import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AccessLevelEnum, ACCOUNT_USER_ACCESS_LEVELS, getAccessLevelLabel, hasAccessLevel } from '../../../../auth/state/auth.utils';
import PERMISSIONS from '../../../../auth/state/permissions';

interface HoveredPopupProperties {
  /** Access level to display in title */
  level: AccessLevelEnum;
  /** Top position relative to window */
  top: number;
  /** Left position relative to window */
  left: number;
  /** Vertical position of the popup relative to tick. Default top */
  verticalPosition: 'top' | 'bottom';
  /** Permissions list and their value (on/off) */
  permissions: { name: string; value: boolean }[];
}

@Component({
  selector: 'permission-level',
  templateUrl: './permission-level.component.html',
  styleUrls: ['./permission-level.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionLevelComponent),
      multi: true
    }
  ]
})
export class PermissionLevelComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  getAccessLevelLabel = getAccessLevelLabel;

  @ViewChildren('levelTicks', { read: ElementRef }) levelTicks: QueryList<ElementRef>;

  accounUserLevels = ACCOUNT_USER_ACCESS_LEVELS;
  value: AccessLevelEnum;
  sliderValue: number;
  hoveredPopup: HoveredPopupProperties;
  disabled: boolean;

  @Input() scrollContainer: HTMLElement;
  @Output() valueChange = new EventEmitter<AccessLevelEnum>();

  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() set level(value: AccessLevelEnum) {
    this.value = value;
    this.sliderValue = this.getLevelIndex(value);
    this.onChange(value);
    this.onTouched();
  }

  get level() {
    return this.value;
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    // Hide popup on scroll
    if (this.scrollContainer) {
      this.scrollContainer.onscroll = () => {
        if (this.hoveredPopup) {
          this.hoveredPopup = null;
        }
      };
    }
  }

  writeValue(value: AccessLevelEnum) {
    this.value = value;
    this.sliderValue = this.getLevelIndex(value);
  }

  getLevelIndex(value: AccessLevelEnum) {
    return this.accounUserLevels.findIndex(l => l === value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  sliderChange(value: number) {
    if (!this.disabled) {
      this.levelChange(this.accounUserLevels[value]);
    }
  }

  levelChange(level: AccessLevelEnum) {
    if (!this.disabled) {
      this.level = level;
      this.valueChange.emit(level);
    }
  }

  onHoveredLevel(level: AccessLevelEnum) {
    if (level) {
      const levelTicks = this.levelTicks.toArray();
      const levelIndex = this.getLevelIndex(level);
      const hoveredTick: HTMLElement = levelTicks[levelIndex].nativeElement;
      const { x, y } = hoveredTick.getBoundingClientRect();

      let overflowing = false;
      const left = x - 85;
      let top = y - 235;
      if (top < 0) {
        top = y + 80;
        overflowing = true;
      }

      this.hoveredPopup = {
        level,
        top,
        left,
        verticalPosition: overflowing ? 'bottom' : 'top',
        permissions: this.getLevelPermissions(level)
      };
    } else {
      this.hoveredPopup = null;
    }
  }

  getLevelPermissions(level: AccessLevelEnum) {
    return [
      { name: 'View model & measurements', value: true },
      { name: 'Add new flight', value: hasAccessLevel(level, PERMISSIONS.flights.create) },
      { name: 'Create measurements', value: hasAccessLevel(level, PERMISSIONS.measurements.create) },
      { name: 'Create analytics', value: hasAccessLevel(level, PERMISSIONS.analytics.create) },
      { name: 'Add designs', value: hasAccessLevel(level, PERMISSIONS.regularDesigns.create) },
      {
        name: 'Manage project plan and activities',
        value: hasAccessLevel(level, PERMISSIONS.projectPlans.create) && hasAccessLevel(level, PERMISSIONS.activities.create)
      },
      {
        name: 'Manage sites and users',
        value: hasAccessLevel(level, PERMISSIONS.sites.create) && hasAccessLevel(level, PERMISSIONS.users.create)
      }
    ];
  }
}
