/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.8.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GeoreferenceDesignVersionModel } from './georeferenceDesignVersionModel';
import { Page } from './page';

export interface GetGeoreferenceDesignResponse {
  categoryId?: string;
  creationTime?: string;
  description?: string;
  fileName?: string;
  geoReferenceUrl?: string;
  id?: string;
  name?: string;
  pages?: Array<Page>;
  sharedVersionId?: string;
  state?: GetGeoreferenceDesignResponse.StateEnum;
  uploaderId?: string;
  versions?: Array<GeoreferenceDesignVersionModel>;
}
export namespace GetGeoreferenceDesignResponse {
  export type StateEnum = 'NOT_LOADED' | 'UPLOADING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED' | 'SYNCING' | 'DELETING';
  export const StateEnum = {
    NOTLOADED: 'NOT_LOADED' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum,
    SYNCING: 'SYNCING' as StateEnum,
    DELETING: 'DELETING' as StateEnum
  };
}
