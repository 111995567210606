import { Component, forwardRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { saveAs } from 'file-saver';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';

import { SnackBarService } from '../services/snackbar.service';
import { formatFileSize } from '../utils/formatting';

@Component({
  selector: 'drag-and-drop-image-control',
  templateUrl: './drag-and-drop-image-control.component.html',
  styleUrls: ['./drag-and-drop-image-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropImageControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DragAndDropImageControlComponent),
      multi: true
    }
  ]
})
export class DragAndDropImageControlComponent implements OnInit, ControlValueAccessor, Validator {
  formatFileSize = formatFileSize;

  @ViewChild(NgxFileDropComponent) ngxFileDrop: NgxFileDropComponent;

  @HostBinding('class.empty') isEmpty = true;

  @Input() disabled: boolean;
  @Input() validFileExtensions = ['.png', '.jpg', '.jpeg'];
  @Input() maxFileSize = 5 * 1024 ** 2; // 5 MB default

  value: File;
  @Input() set imageFile(value: File) {
    this.value = value;
    this.isEmpty = !value;
    this.onChange(value);
    this.onTouched();

    this.loadImage(value);
  }
  get imageFile() {
    return this.value;
  }
  imageUrl: string;
  isValid = true;

  accept: string;
  bottomText: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private snackbar: SnackBarService) {}

  ngOnInit() {
    this.accept = this.validFileExtensions.join(', ');

    const fileExtensions = this.accept.toUpperCase();
    const maxFileSize = this.formatFileSize(this.maxFileSize);
    this.bottomText = $localize`:@@shared.dragAndDropImageControl.bottomText:${fileExtensions} up to ${maxFileSize}`;
  }

  writeValue(value: File) {
    this.imageFile = value;
  }

  registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private loadImage(imageFile: File) {
    if (imageFile) {
      if (this.isValid) {
        const reader = new FileReader();
        reader.onload = event => {
          this.imageUrl = event.target.result as string;
        };

        reader.onerror = event => {
          this.imageUrl = null;
          const msg = 'Error: File could not be read';
          console.error(msg, event.target.error);
        };

        reader.readAsDataURL(imageFile);
      } else {
        this.imageUrl = '/assets/no-thumbnail.png';
      }
    } else {
      this.imageUrl = null;
      this.isEmpty = true;
    }
  }

  openFileSelector() {
    if (this.disabled) {
      return;
    }
    this.ngxFileDrop.openFileSelector();
  }

  async onDrop(files: NgxFileDropEntry[]) {
    if (this.disabled) {
      return;
    }

    const fileEntry = files[0];
    if (!fileEntry || !fileEntry.fileEntry.isFile) {
      this.snackbar.openError(
        $localize`:@@shared.dragAndDropImageControl.errorFileDoesntExist:Error: File does not exist or is a directory`,
        'Error: File does not exist or is a directory'
      );
      return;
    }

    const file = await this.fileEntryToFile(fileEntry);
    if (!file) {
      this.snackbar.openError(
        $localize`:@@shared.dragAndDropImageControl.errorCantLoadFile:Error: File could not be loaded`,
        'Error: File could not be loaded'
      );
      return;
    }

    this.imageFile = file;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.validateFile(control.value);
  }

  private validateFile(file: File) {
    const errors: ValidationErrors = {};
    if (file) {
      if (!this.validFileExtensions.some(type => file.name.toLowerCase().endsWith(type))) {
        errors.invalidFileExtension = {
          validFileExtensions: this.validFileExtensions
        };
      }

      if (file.size > this.maxFileSize) {
        errors.exceedsMaxSize = {
          maxFileSize: this.maxFileSize,
          fileSize: file.size
        };
      }
    }

    this.isValid = Object.keys(errors).length === 0;
    return !this.isValid ? errors : null;
  }

  onDelete() {
    this.imageFile = null;
  }

  onDownload() {
    if (!this.imageUrl || !this.isValid || this.isEmpty) {
      return;
    }

    saveAs(this.imageUrl, this.imageFile?.name);
  }

  private fileEntryToFile = (fileDropEntry: NgxFileDropEntry) => {
    return new Promise<File>(resolve => {
      const fileEntry = fileDropEntry.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => resolve(file));
    });
  };
}
