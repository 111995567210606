import { Injectable } from '@angular/core';
import { EntityState, EntityStore, isDefined, MultiActiveState, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';

import { CloudFrontPreSignedPolicy } from '../../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import {
  Design,
  DesignActiveLayer,
  DesignCategory,
  DesignChatSessionsHistory,
  DesignLayerProperty,
  DesignsUploadingState,
  DesignType,
  GeoReferencedDesign,
  IntegrationDesignNode,
  RegularDesign,
  RoadDesign
} from './detailed-site-designs.model';

export interface DetailedSiteDesignsState {
  siteId: string;
  viewerUrlsCredentials?: CloudFrontPreSignedPolicy;
  designsUploading: DesignsUploadingState;
  activeDesignLayer?: DesignActiveLayer;
  expandedDesignIDs?: Set<string>;
  activeDesignChatId: string;
}

export interface RegularDesignState extends EntityState<RegularDesign>, MultiActiveState {}
export interface RoadDesignState extends EntityState<RoadDesign>, MultiActiveState {}
export interface GeoReferencedDesignState extends EntityState<GeoReferencedDesign>, MultiActiveState {}

export interface DesignCategoryState extends EntityState<DesignCategory> {}

export interface IntegrationDesignState extends EntityState<IntegrationDesignNode>, MultiActiveState {}

export interface DesignChatSessionsHistoryState extends EntityState<DesignChatSessionsHistory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-designs-uploading' })
export class DetailedSiteDesignsStore extends Store<DetailedSiteDesignsState> {
  regularDesigns = new EntityStore<RegularDesignState>({}, { name: 'site-regular-designs' });
  roadDesigns = new EntityStore<RoadDesignState>({}, { name: 'site-road-designs' });
  geoReferencedDesigns = new EntityStore<GeoReferencedDesignState>({}, { name: 'site-geo-reference-designs' });
  designCategories = new EntityStore<DesignCategoryState>({}, { name: 'site-designs-categories' });
  integrationDesignNodes = new EntityStore<IntegrationDesignState>({ active: [] }, { name: 'integration-design-nodes', idKey: 'id' });
  designChatSessionsHistory = new EntityStore<DesignChatSessionsHistoryState>(
    {},
    { name: 'design-chat-session-history', idKey: 'sessionId' }
  );
  designChatSessionsFavorites = new EntityStore<DesignChatSessionsHistoryState>(
    {},
    { name: 'design-chat-session-favorites', idKey: 'sessionId' }
  );

  constructor() {
    super({
      siteId: null,
      viewerUrlsCredentials: null,
      designsUploading: null,
      activeDesignLayer: { designId: null, layerId: null, layerName: null, featureIds: null, properties: null },
      expandedDesignIDs: new Set(),
      activeDesignChatId: null
    });
  }

  initStore(siteId: string, viewerCredentials: CloudFrontPreSignedPolicy) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.siteId = siteId;
        draftState.viewerUrlsCredentials = viewerCredentials;
      })
    );
  }

  setActiveDesignLayerProperties(properties: DesignLayerProperty[]) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.activeDesignLayer.properties = properties;
      })
    );
  }

  setDesignActiveLayer(designId: string, layerId?: string, childLayers?: string[], layerName?: string) {
    const featureIds = isDefined(childLayers) ? new Set(childLayers) : null;
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.activeDesignLayer = { ...draftState.activeDesignLayer, designId, layerId, featureIds, layerName };
      })
    );
  }

  setActiveDesignChat(designId: string) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.activeDesignChatId = designId;
      })
    );
  }

  upsertRegularDesigns(regularDesigns: RegularDesign[]) {
    this.regularDesigns.upsertMany(regularDesigns);
  }

  upsertRoadDesigns(roadDesigns: RoadDesign[]) {
    this.roadDesigns.upsertMany(roadDesigns);
  }

  upsertGeoReferencedDesigns(geoReferencedDesigns: GeoReferencedDesign[]) {
    this.geoReferencedDesigns.upsertMany(geoReferencedDesigns);
  }

  addRegularDesign(regularDesign: RegularDesign) {
    this.regularDesigns.add(regularDesign);
  }

  updateRegularDesign(designId: string, design: Partial<RegularDesign>) {
    this.regularDesigns.update(designId, design);
  }

  updateRoadDesign(roadDesignId: string, design: Partial<RoadDesign>) {
    this.roadDesigns.update(roadDesignId, design);
  }

  updateGeoReferencedDesign(geoReferencedDesignId: string, design: Partial<GeoReferencedDesign>) {
    this.geoReferencedDesigns.update(geoReferencedDesignId, design);
  }

  deleteRegularDesign(designId: string) {
    this.regularDesigns.remove(designId);
  }

  deleteRoadDesign(roadDesignId: string) {
    this.roadDesigns.remove(roadDesignId);
  }

  deleteGeoReferencedDesign(geoReferencedDesignId: string) {
    this.geoReferencedDesigns.remove(geoReferencedDesignId);
  }

  setDesignCategories(categories: DesignCategory[]) {
    this.designCategories.upsertMany(categories);
  }

  addDesignCategory(category: DesignCategory) {
    this.designCategories.add(category);
  }

  updateDesignCategory(category: DesignCategory) {
    this.designCategories.update(category.id, category);
  }

  setDesignLayers(designId: string, type: DesignType, show: boolean, selectedLayersIds?: Set<string>) {
    const updateDesignLayers = <T extends Design>(design: T): T => {
      if (!isDefined(design?.layers)) {
        return design;
      }

      let allIsShown = true;
      let updatedLayers = design.layers.map(layer => {
        const showLayer = selectedLayersIds?.has(layer.id) || show;
        if (!showLayer && !layer.expandable) {
          allIsShown = false;
        }
        return { ...layer, show: !layer.expandable ? showLayer : false };
      });

      return { ...design, layers: updatedLayers, allIsShown };
    };

    if (type === DesignType.REGULAR_DESIGN) {
      this.regularDesigns.update(designId, updateDesignLayers);
    } else if (type === DesignType.GEO_REFERENCED_DESIGN) {
      this.geoReferencedDesigns.update(designId, updateDesignLayers);
    } else if (type === DesignType.ROAD_DESIGN) {
      this.roadDesigns.update(designId, updateDesignLayers);
    }
  }

  setDesignsUploadingState(designsUploading: Partial<DesignsUploadingState>) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        if (designsUploading) {
          draftState.designsUploading = {
            totalFiles: (designsUploading.totalFiles || draftState.designsUploading?.totalFiles) ?? 0,
            uploadedFiles: (designsUploading.uploadedFiles || draftState.designsUploading?.uploadedFiles) ?? 0,
            totalSize: (designsUploading.totalSize || draftState.designsUploading?.totalSize) ?? 0,
            uploadedSize: (designsUploading.uploadedSize || draftState.designsUploading?.uploadedSize) ?? 0,
            validating: designsUploading.validating
          };
        } else {
          draftState.designsUploading = null;
        }
      })
    );
  }

  reset() {
    super.reset();
    this.regularDesigns.reset();
    this.roadDesigns.reset();
    this.geoReferencedDesigns.reset();
    this.designCategories.reset();
    this.integrationDesignNodes.reset();
    this.clearDesignChatHistory();
  }

  upsertIntegrationDesignNodes(nodes: IntegrationDesignNode[]) {
    this.integrationDesignNodes.upsertMany(nodes);
  }

  updateIntegrationDesignNode(node: Partial<IntegrationDesignNode>) {
    this.integrationDesignNodes.update(node.id, node);
  }

  updateExpandedDesignIDs(expandedDesignIDs: Set<string>) {
    this.update(
      produce((draftState: DetailedSiteDesignsState) => {
        draftState.expandedDesignIDs = expandedDesignIDs;
      })
    );
  }

  setDesignChatHistory(history: DesignChatSessionsHistory[]) {
    this.designChatSessionsHistory.upsertMany(history);
  }

  setDesignChatFavorites(favorites: DesignChatSessionsHistory[]) {
    this.designChatSessionsFavorites.upsertMany(favorites);
  }

  addChatSessionToHistory(session: DesignChatSessionsHistory) {
    this.designChatSessionsHistory.upsert(session.sessionId, session);
  }

  addChatSessionToFavorites(session: DesignChatSessionsHistory) {
    this.designChatSessionsFavorites.upsert(session.sessionId, session);
  }

  removeChatSessionFromFavorites(sessionId: string) {
    this.designChatSessionsFavorites.remove(sessionId);
  }

  updateChatSession(session: Partial<DesignChatSessionsHistory>) {
    this.designChatSessionsHistory.update(session.sessionId, session);
  }

  deletedChatSession(sessionId: string) {
    this.designChatSessionsHistory.remove(sessionId);
  }

  clearDesignChatHistory() {
    this.designChatSessionsHistory.reset();
    this.designChatSessionsFavorites.reset();
  }
}
