/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.8.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DesignErrorModel } from './designErrorModel';
import { DesignVersionModel } from './designVersionModel';

export interface GetDesignResponse {
  activeDesignId?: string;
  categoryId?: string;
  cesium3DReady?: boolean;
  chatReady?: boolean;
  creationTime?: Date;
  description?: string;
  errors?: Array<DesignErrorModel>;
  fileName?: string;
  hasBorder?: boolean;
  hasSurface?: boolean;
  host?: boolean;
  id?: string;
  ifcTreeFileUrl?: string;
  integrationFilePath?: string;
  jsonReady?: boolean;
  name?: string;
  sharedVersionId?: string;
  siteId?: string;
  state?: GetDesignResponse.StateEnum;
  sync?: boolean;
  terrainReady?: boolean;
  url?: string;
  versions?: Array<DesignVersionModel>;
  xrefParentId?: string;
}
export namespace GetDesignResponse {
  export type StateEnum = 'NOT_LOADED' | 'UPLOADING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED' | 'SYNCING' | 'DELETING';
  export const StateEnum = {
    NOTLOADED: 'NOT_LOADED' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum,
    SYNCING: 'SYNCING' as StateEnum,
    DELETING: 'DELETING' as StateEnum
  };
}
