<div class="resizeable-container" #resizeableContainerRef>
  <div class="image-wrapper">
    <div *ngIf="!scrolled && !hideHint && withCtrl && loaded" class="hint" i18n="@@shared.zoomImage.hint">Ctrl + scroll to zoom</div>
    <div
      #imageContainerRef
      class="image-container"
      (mousedown)="onImageMouseDown($event)"
      (mouseup)="onImageMouseUp($event)"
      (wheel)="onImageWheel($event)"
      (mousemove)="onImageMouseMove($event)"
      (mouseleave)="onImageMouseLeave($event)"
      (contextmenu)="imageContainerContextMenu.emit($event)"
    >
      <img #imageRef [src]="src" [alt]="alt" />
      <ng-content />
    </div>
  </div>
</div>
