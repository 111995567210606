/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.8.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InputFilesModel } from './inputFilesModel';
import { RoadDesignErrorModel } from './roadDesignErrorModel';
import { RoadDesignVersionModel } from './roadDesignVersionModel';

export interface GetRoadDesignResponse {
  categoryId?: string;
  creationTime?: Date;
  endStationDouble?: number;
  errors?: Array<RoadDesignErrorModel>;
  file1Name?: string;
  file2Name?: string;
  file3Name?: string;
  id?: string;
  inputFilesUrl?: InputFilesModel;
  jsonReady?: boolean;
  meshReady?: boolean;
  name?: string;
  roadDesignType?: GetRoadDesignResponse.RoadDesignTypeEnum;
  roadName?: string;
  sharedVersionId?: string;
  siteId?: string;
  startRun?: number;
  startStation?: string;
  startStationDouble?: number;
  state?: GetRoadDesignResponse.StateEnum;
  stationInterval?: number;
  stationNamingFormatType?: GetRoadDesignResponse.StationNamingFormatTypeEnum;
  uploaderId?: string;
  versions?: Array<RoadDesignVersionModel>;
}
export namespace GetRoadDesignResponse {
  export type RoadDesignTypeEnum = 'SURFACE' | 'VECTOR';
  export const RoadDesignTypeEnum = {
    SURFACE: 'SURFACE' as RoadDesignTypeEnum,
    VECTOR: 'VECTOR' as RoadDesignTypeEnum
  };
  export type StateEnum = 'NOT_LOADED' | 'UPLOADING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED' | 'SYNCING' | 'DELETING';
  export const StateEnum = {
    NOTLOADED: 'NOT_LOADED' as StateEnum,
    UPLOADING: 'UPLOADING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum,
    SYNCING: 'SYNCING' as StateEnum,
    DELETING: 'DELETING' as StateEnum
  };
  export type StationNamingFormatTypeEnum = 'FORMAT_A' | 'FORMAT_B' | 'FORMAT_C';
  export const StationNamingFormatTypeEnum = {
    A: 'FORMAT_A' as StationNamingFormatTypeEnum,
    B: 'FORMAT_B' as StationNamingFormatTypeEnum,
    C: 'FORMAT_C' as StationNamingFormatTypeEnum
  };
}
