import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';
import { featureCollection } from '@turf/turf';
import { Feature, FeatureCollection } from 'geojson';
import { flatten, merge } from 'lodash';
import { concat, forkJoin, from, lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import { BaseSurface } from '../../../../generated/mms/model/baseSurface';
import { CoordinateBlock } from '../../../../generated/mms/model/coordinateBlock';
import { CoordinateModel } from '../../../../generated/mms/model/coordinateModel';
import { CreateDrawingIdsResponse } from '../../../../generated/mms/model/createDrawingIdsResponse';
import { CreateDrawingsRequest } from '../../../../generated/mms/model/createDrawingsRequest';
import { DeleteDrawingIdsRequest } from '../../../../generated/mms/model/deleteDrawingIdsRequest';
import { GetAllAnalyticsResponse } from '../../../../generated/mms/model/getAllAnalyticsResponse';
import { GetAllAnnotationsResponse } from '../../../../generated/mms/model/getAllAnnotationsResponse';
import { GetAllGroupsResponse } from '../../../../generated/mms/model/getAllGroupsResponse';
import { GetAllLayersResponse } from '../../../../generated/mms/model/getAllLayersResponse';
import { GetAllMeasurementsResponse } from '../../../../generated/mms/model/getAllMeasurementsResponse';
import { GetAllModelEditsResponse } from '../../../../generated/mms/model/getAllModelEditsResponse';
import { GetAnalyticResponse } from '../../../../generated/mms/model/getAnalyticResponse';
import { GetAnnotationResponse } from '../../../../generated/mms/model/getAnnotationResponse';
import { GetDrawingResponse } from '../../../../generated/mms/model/getDrawingResponse';
import { GetMeasurementResponse } from '../../../../generated/mms/model/getMeasurementResponse';
import { GetModelEditResponse } from '../../../../generated/mms/model/getModelEditResponse';
import { UpdateDrawingsRequest } from '../../../../generated/mms/model/updateDrawingsRequest';
import { AuthQuery } from '../../../auth/state/auth.query';
import { AccessLevelEnum, ACCOUNT_USER_ACCESS_LEVELS, REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { PolygonPolylineEditorObservable } from '../../../shared/utils/cesium-common';
import { convertCoordinateCSToPosition, convertCoordinateToSiteCS } from '../../../shared/utils/cs-conversions';
import { isDefined } from '../../../shared/utils/general';
import { GeoUtils } from '../../../shared/utils/geo';
import { gzip, GZIP_HEADERS } from '../../../shared/utils/gzip-utils';
import { Site } from '../../../tenant/tenant.model';
import { DEFAULT_DRAWING_STYLE_PROPS } from '../../measurements-details-box/annotation-details-box-content/annotation-utils.service';
import { CALC_SERVICE_MAPPING } from '../../measurements-details-box/calculations/general-calc.service';
import {
  CalcService,
  GeoJsonProperties,
  PolygonWithSamplesCalcService,
  PolylineCalcService,
  PrecalcData
} from '../../services/calc-services';
import { SiteMapService } from '../../services/site-map.service';
import { TerrainProviderService } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { CalcModelOption, CalcModelType, Task } from '../detailed-site.model';
import { DetailedSiteQuery } from '../detailed-site.query';
import {
  DEFAULT_VIZ_OPTIONS,
  DeltaElevationVizOptions,
  EntityChartOptions,
  entityTypePermissions,
  generateTempEntityId,
  getDataValue,
  getLatestDataVersion,
  isLatestDataVersion,
  isNewEntity,
  sortCalcResultFields
} from '../detailed-site.utils';
import { DetailedSiteDesignsQuery } from '../detailed-site-designs/detailed-site-designs.query';
import {
  Analytic,
  AnalyticType,
  Annotation,
  AnnotationFile,
  AnnotationNote,
  AnnotationPriority,
  AnnotationStatus,
  AnnotationType,
  BaseSurfaceType,
  CalcModelValues,
  CalcStatus,
  DataValue,
  DEFAULT_GROUP_LAYER_ID,
  DEFAULT_LAYER,
  Drawing,
  DrawingEditor,
  EntityType,
  EntityTypeWithBaseSurface,
  EntityTypeWithGeoJson,
  EntityTypeWithLinkedResources,
  getResourceTypeFromEntityType,
  Group,
  Layer,
  MapEntity,
  Measurement,
  MeasurementType,
  ModelEdit,
  ModelEditType,
  PolygonType,
  StoreDrawingStyleProps
} from './detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from './detailed-site-entities.query';
import { DetailedSiteEntitiesStore } from './detailed-site-entities.store';

@Injectable({ providedIn: 'root' })
export class DetailedSiteEntitiesService {
  constructor(
    private siteEntitiesStore: DetailedSiteEntitiesStore,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery,
    private siteDesignsQuery: DetailedSiteDesignsQuery,
    private http: HttpClient,
    private authQuery: AuthQuery,
    private terrainProviderService: TerrainProviderService,
    private analyticsService: AnalyticsService,
    private terrainSampling: TerrainSamplingService,
    private siteQuery: DetailedSiteQuery,
    private injector: Injector,
    private snackbar: SnackBarService,
    private siteMapService: SiteMapService,
    private resourceLinksService: ResourceLinksService
  ) {}

  init(site: Site) {
    this.siteEntitiesStore.initStore(site.id, site.coordinateSystem);
  }

  resetStore() {
    this.siteEntitiesStore.reset();
  }

  setMapVisualizationLoading(loading: boolean) {
    this.siteEntitiesStore.setMapVisualizationLoading(loading);
  }

  setCalculationLoading(loading: boolean) {
    this.siteEntitiesStore.setCalculationLoading(loading);
  }

  setDeltaCalculationLoading(loading: boolean) {
    this.siteEntitiesStore.setDeltaCalculationLoading(loading);
  }

  fetchModelEdits(siteId: string) {
    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/modelEdits`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.modelEdits.read }
      })
      .pipe(
        tap((response: GetAllModelEditsResponse) => {
          if (response && response.modelEdits) {
            this.siteEntitiesStore.setModelEdits(response.modelEdits.map(edit => this.toLocalModelEdit(edit)));
          }
        })
      );
  }

  private toLocalModelEdit(entity: GetModelEditResponse) {
    const localModelEdit: ModelEdit = {
      ...entity,
      pinned: false,
      inEdit: false,
      sourceModel: entity.sourceModel,
      type: entity.type,
      positions: this.generatePositionsFromServerEntity(entity),
      calcResult: entity.data?.map(({ id, type, values }) => ({
        id,
        type: type as CalcModelType,
        values: this.generateCalcResult(entity.type, values)
      }))
    };

    return localModelEdit;
  }

  removeTaskFromModelEdits(edits: ModelEdit[], taskId: string) {
    this.siteEntitiesStore.removeTaskFromModelEdits(edits, taskId);
  }

  async addTaskToModelEdit(edit: ModelEdit, taskId: string) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    const terrain = await this.terrainProviderService.getTerrainProvider(siteId, taskId, 'TASK', edit.sourceModel);
    const taskSampledPositions = await this.terrainSampling.sampleTerrain(edit.positions, terrain, false);
    const taskPositionsElevation = taskSampledPositions.map(p => p.height);

    this.siteEntitiesStore.addTaskToModelEdits([edit], taskId, taskPositionsElevation);
  }

  setCrossSectionPoint(point: { show?: boolean; position?: Cartesian3 }) {
    this.siteEntitiesStore.updateCrossPoint(point);
  }

  setActiveEntity(entity: MapEntity) {
    if (this.siteEntitiesQuery.hasDrawings()) {
      this.removeDrawingsFromStore(this.siteEntitiesQuery.getAllDrawings().map(d => d.id));
      this.resetActiveDrawingStyleProps();
    }

    if (this.siteEntitiesQuery.isDrawingToolsOpened()) {
      this.showDrawingTools(false);
    }

    const activeEntity = this.siteEntitiesQuery.getActiveEntity();

    if (activeEntity && entity && activeEntity.id === entity.id) {
      // Do nothing if entity is already active
      return;
    }

    if (entity) {
      if (activeEntity) {
        // Deactivate current active entity
        this.setEntityInEdit(activeEntity, false);
        this.setActiveEditor(null);
        this.siteEntitiesStore.setActiveEntity(null);
      }
      // Activate created / opened entity
      this.setEntityInEdit(entity, true);
      this.analyticsService.setActiveEntity(entity);
      this.fetchEntityResourceLinks(entity).subscribe(() => {
        // Set entity is active after resource link fetch - in order to 'isFirstLoad' and 'DirtyCheck' works as before
        this.siteEntitiesStore.setActiveEntity(entity);
        this.fetchEntityGeoJson(entity).then(() => this.recalcIfEntityDataNotActualOrValid(entity?.id, entity?.type));
      });
    } else {
      if (activeEntity) {
        this.setEntityInEdit(activeEntity, false);
      }
      this.setActiveEditor(null);
      this.siteEntitiesStore.setActiveEntity(null);
    }
  }

  private async fetchEntityGeoJson(entity: MapEntity) {
    if (entity.type in EntityTypeWithGeoJson && !entity.geoJson) {
      // New entity without geoJson - add empty geoJson locally
      // Existing entity without geoJson - take from server and update locally
      await this.updateLocalEntityGeoJson(entity);
    }
  }

  fetchEntityResourceLinks(entity: MapEntity) {
    if (!isNewEntity(entity.id) && entity.type in EntityTypeWithLinkedResources) {
      const siteId = this.siteQuery.getSiteId();
      return this.resourceLinksService.fetchResourceLinks(siteId, entity.id, getResourceTypeFromEntityType(entity.type));
    }

    return of(null);
  }

  private async recalcIfEntityDataNotActualOrValid(entityId: string, entityType: EntityType) {
    if (!entityId || !entityType) {
      return;
    }

    let entity = this.siteEntitiesQuery.getEntity(entityId, entityType);

    // Don't recalc for new entity / annotation / an entity with unselected base surface
    if (isNewEntity(entity.id) || entity.type in AnnotationType || (entity.type in EntityTypeWithBaseSurface && !entity.baseSurface)) {
      return;
    }

    // Recalc entity if it doesn't have calcRes or geojson / its data version is old / it doesn't have texture data
    const isFullRecalc =
      !entity.calcResult || entity.calcResult.length === 0 || !isLatestDataVersion(entity) || !this.hasTextureData(entity);

    if (!isFullRecalc) {
      return;
    }

    this.setCalculationLoading(true);
    await this.updateCalcResults(entity, false, false);
    entity = this.siteEntitiesQuery.getEntity(entity.id, entity.type);

    concat(
      this.serverUpdateEntity(entity),
      entity.type in EntityTypeWithGeoJson ? this.updateEntityGeoJson(entity, entity.geoJson) : of(null)
    ).subscribe({
      complete: () => this.setCalculationLoading(false),
      error: error => {
        this.setCalculationLoading(false);

        this.snackbar.openError('Error saving calculation results', error);
      }
    });
  }

  private async updateLocalEntityGeoJson(entity: MapEntity) {
    let geoJson: FeatureCollection;
    if (isNewEntity(entity.id)) {
      geoJson = featureCollection([]);
    } else {
      this.setCalculationLoading(true);
      geoJson = await lastValueFrom(this.getEntitySampleData(entity));
      this.setCalculationLoading(false);
    }

    this.updateEntity(entity.id, { type: entity.type, geoJson, showMapVizLayer: true } as Partial<MapEntity>);
  }

  private setEntityInEdit(entity: MapEntity, inEdit: boolean) {
    this.updateEntity(entity.id, { type: entity.type, inEdit } as Partial<MapEntity>);
  }

  fetchSiteAnalytics(siteId: string) {
    this.resetAnalytics();

    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/analytics`, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.analytics.read } })
      .pipe(
        tap((response: GetAllAnalyticsResponse) => {
          if (response && response.analytics) {
            this.siteEntitiesStore.setAnalytics(response.analytics.map(entity => this.toLocalAnalytic(entity)));
          }
        })
      );
  }

  private toLocalAnalytic(entity: GetAnalyticResponse) {
    const localAnalytic: Analytic = {
      ...entity,
      pinned: false,
      inEdit: false,
      name: entity.name,
      type: entity.type,
      sourceModel: entity.sourceModel,
      positions: this.generatePositionsFromServerEntity(entity),
      calcResult: entity.data?.map(({ id, type, values }) => ({
        id,
        type: type as CalcModelType,
        values: this.generateCalcResult(entity.type, values)
      })),
      geoJson: null
    };

    return localAnalytic;
  }

  private generateCalcResult(type: EntityType, values: { [key: string]: number }): DataValue[] {
    return (
      values &&
      Object.entries(values)
        .map(([field, value]) => getDataValue(field, value))
        .sort(sortCalcResultFields(type))
    );
  }

  private generatePositionsFromServerEntity(
    entity: GetAnalyticResponse | GetMeasurementResponse | GetAnnotationResponse | GetModelEditResponse | GetDrawingResponse
  ) {
    let positions: Cartesian3[];
    const siteCS = this.siteEntitiesQuery.getSiteCoordinateSystem();
    if (entity?.coordinates && entity.coordinates.length > 0 && siteCS) {
      positions = entity.coordinates.map((c: CoordinateModel) => {
        const position = convertCoordinateCSToPosition(c as any, siteCS);
        return position && Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, position.height);
      });
      if (positions.filter(p => !!p).length !== entity.coordinates.length) {
        positions = null;
      }
    }
    if (!positions && entity?.positions) {
      positions = entity.positions.map((c: CoordinateBlock) => new Cesium.Cartesian3(c.x, c.y, c.z));
    }
    return positions;
  }

  private resetAnalytics() {
    const analytics = this.siteEntitiesQuery.analyticsQuery.getAll();
    this.siteEntitiesStore.removeEntities(analytics);
  }

  fetchSiteAnnotations(siteId: string) {
    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/annotations`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotations.read }
      })
      .pipe(
        tap((response: GetAllAnnotationsResponse) => {
          if (response && response.annotations) {
            this.siteEntitiesStore.setAnnotations(response.annotations.map(entity => this.toLocalAnnotation(entity)));
          }
        })
      );
  }

  private toLocalAnnotation(entity: GetAnnotationResponse) {
    const activeUserId = this.authQuery.getUserId();
    const isTenantAdmin = this.authQuery.hasAccessLevel(AccessLevelEnum.TENANTADMIN);
    const localAnnotation = {
      ...entity,
      type: AnnotationType.ANNOTATION,
      pinned: false,
      inEdit: false,
      positions: this.generatePositionsFromServerEntity(entity),
      isEditable: isDefined(entity.createdBy) ? isTenantAdmin || activeUserId === entity.createdBy.userId : true
    } as Annotation;

    if (isDefined(entity.drawings)) {
      localAnnotation.drawings = entity.drawings.map(drawing => ({
        ...drawing,
        positions: this.generatePositionsFromServerEntity(drawing),
        isEditable: isTenantAdmin || activeUserId === drawing.createdBy?.userId
      }));
    }
    if (isDefined(entity.attachments)) {
      localAnnotation.attachments = entity.attachments.map(file => ({
        ...file,
        isEditable: isTenantAdmin || activeUserId === file.uploadedBy?.userId
      }));
    }

    return localAnnotation;
  }

  fetchMeasurements(siteId: string, taskId: string) {
    this.siteEntitiesStore.measurements.setLoading(true);
    this.resetMeasurements();

    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/measurements`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measurements.read }
      })
      .pipe(
        tap((response: GetAllMeasurementsResponse) => {
          if (response && response.measurements) {
            this.siteEntitiesStore.setMeasurements(response.measurements.map(entity => this.toLocalMeasurement(entity)));
          }
        }),
        finalize(() => this.siteEntitiesStore.measurements.setLoading(false))
      );
  }

  fetchMeasurementsById(siteId: string, ids: string[]) {
    return this.http
      .post(
        `${getServiceUrl('mms')}/sites/${siteId}/measurements/byIdsList`,
        { ids },
        {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measurements.read }
        }
      )
      .pipe(
        map((response: GetAllMeasurementsResponse) => {
          return response?.measurements?.map(entity => this.toLocalMeasurement(entity));
        })
      );
  }

  private resetMeasurements() {
    const measurements = this.siteEntitiesQuery.measurementsQuery.getAll();
    this.siteEntitiesStore.removeEntities(measurements);
  }

  private toLocalMeasurement(entity: GetMeasurementResponse) {
    const localMeasurement: Measurement = {
      ...entity,
      name: entity.name,
      type: entity.type,
      sourceModel: entity.sourceModel,
      pinned: false,
      inEdit: false,
      positions: this.generatePositionsFromServerEntity(entity),
      calcResult: [{ id: entity.taskId, type: CalcModelType.TASK, values: this.generateCalcResult(entity.type, entity.values) }],
      geoJson: null
    };

    return localMeasurement;
  }

  addEntity(entity: MapEntity, shouldMakeActiveEntity = true) {
    const localEntity = this.initLocalEntity(entity);
    this.siteEntitiesStore.addEntity(localEntity);
    if (shouldMakeActiveEntity) {
      this.setActiveEntity(localEntity as MapEntity);
    }
    return localEntity;
  }

  cloneMeasurement(entity: MapEntity, task: Task) {
    const newEntity = {
      ...entity,
      id: generateTempEntityId(),
      taskId: task.id,
      calcResult: null
    } as MapEntity;

    return this.serverSaveEntity(newEntity, false);
  }

  cloneMeasurements(entity: MapEntity, tasks: Task[]) {
    return forkJoin(tasks.map(task => this.cloneMeasurement(entity, task)));
  }

  updateEntity(id: string, entity: Partial<MapEntity>) {
    this.siteEntitiesStore.updateEntity({ ...entity, id });
  }

  async updateEntityBaseSurfaceAndCalcResults(entity: MapEntity, baseSurface: BaseSurface) {
    if (entity.type in EntityTypeWithBaseSurface && baseSurface) {
      if (baseSurface.type === BaseSurfaceType.DESIGN) {
        const currentDesignVersionId = this.siteDesignsQuery.getCurrentRegularDesignIdByVersionId(baseSurface.id);
        if (currentDesignVersionId !== baseSurface.id) {
          baseSurface = { ...baseSurface, id: currentDesignVersionId };
        }
      }
      this.updateEntity(entity.id, { type: entity.type, baseSurface, showMapVizLayer: false });
      entity = this.siteEntitiesQuery.getEntity(entity.id, entity.type);
      await this.updateCalcResults(entity, !isDefined(entity?.calcResult));
    }
  }

  async addCalcModelToEntityCalc(entity: MapEntity, calcModelOptions: CalcModelOption[] | CalcModelOption) {
    if (!(calcModelOptions instanceof Array)) {
      calcModelOptions = [calcModelOptions];
    }

    const entityVertices = entity.positions;
    if (!entityVertices || entityVertices.length === 0) {
      return;
    }
    const activeTaskId = this.siteQuery.getActiveTaskId();
    const hasActiveTask = !!calcModelOptions.find(({ id, type }) => type === CalcModelType.TASK && id === activeTaskId);
    const hasInactiveTask = !hasActiveTask || calcModelOptions.length > 1;

    if (hasActiveTask) {
      this.setCalculationLoading(true);
    }
    if (hasInactiveTask) {
      this.setDeltaCalculationLoading(true);
    }

    const calcService = this.injector.get<CalcService>(CALC_SERVICE_MAPPING[entity.type]);

    let precalcData: PrecalcData;
    if (calcService instanceof PolygonWithSamplesCalcService || calcService instanceof PolylineCalcService) {
      precalcData = calcService.precalc(entity.type in PolygonType ? [...entityVertices, entityVertices[0]] : entityVertices);
    }

    const siteId = this.siteQuery.getSiteId();
    const { calcResult, samplePoints, calcStatus } = await calcService.calcResults(entity, siteId, calcModelOptions, precalcData);
    if (entity.type in EntityTypeWithGeoJson && samplePoints) {
      entity = this.updateVizOptionsAfterCalc(entity, samplePoints);
    }

    const updatedFields = {
      dataVersion: getLatestDataVersion(entity.type),
      calcResult,
      calcStatus: calcStatus ?? CalcStatus.SUCCESS,
      showMapVizLayer: true
    };

    this.updateEntity(entity.id, {
      ...entity,
      ...updatedFields
    } as Partial<MapEntity>);

    if (hasActiveTask) {
      this.setCalculationLoading(false);
    }
    if (hasInactiveTask) {
      this.setDeltaCalculationLoading(false);
    }
  }

  removeCalcModelFromEntityCalc(entity: MapEntity, calcModelOption: CalcModelOption) {
    const updatedFields: Partial<MapEntity> = {
      calcResult: entity.calcResult?.filter(({ id, type }) => id !== calcModelOption.id || type !== calcModelOption.type)
    };

    if (entity.type in EntityTypeWithGeoJson) {
      const samplePoints = entity.geoJson.features[0];
      const featureProperties = samplePoints.properties as GeoJsonProperties;
      const newSamplePoints = {
        ...samplePoints,
        properties: {
          ...featureProperties,
          terrainSamplesValues: featureProperties.terrainSamplesValues.filter(
            ({ id, type }) => id !== calcModelOption.id || type !== calcModelOption.type
          )
        }
      };
      if (entity.type in EntityTypeWithBaseSurface) {
        newSamplePoints.properties.surfaceSamplesHeights = featureProperties.surfaceSamplesHeights.filter(
          data => data.id !== calcModelOption.id
        );
      }
      updatedFields.geoJson = { ...entity.geoJson, features: [newSamplePoints] };
    }

    this.updateEntity(entity.id, {
      ...entity,
      ...updatedFields
    } as Partial<MapEntity>);
  }

  removeEntity = (entityId: string, entityType: EntityType) => {
    const entity = this.siteEntitiesQuery.getEntity(entityId, entityType);
    if (!entity) {
      return of(null);
    }

    this.removeEntityFromStore(entity);

    if (!isNewEntity(entity.id)) {
      // If entity is model edit - invalidate appropriate terrains
      if (entity.type in ModelEditType) {
        const siteId = this.siteEntitiesQuery.getSiteId();
        const edit = entity as ModelEdit;
        const taskIds = Object.keys(edit.positionsElevation);
        taskIds.forEach(taskId => this.terrainProviderService.invalidateTerrainProvider(siteId, taskId, 'TASK', edit.sourceModel));
      }
      this.analyticsService.deleteEntity(entity);
      this.resourceLinksService.removeResource(entity.id, getResourceTypeFromEntityType(entity.type));

      return this.serverRemoveEntity(entity);
    }

    return of(null);
  };

  removeEntityFromStore(entity: MapEntity) {
    this.siteEntitiesStore.removeEntity(entity.id, entity.type);
  }

  private serverRemoveEntity(entity: MapEntity) {
    // Can't delte new entity
    if (isNewEntity(entity.id)) {
      return of(null);
    }

    if ('type' in entity) {
      const siteId = this.siteEntitiesQuery.getSiteId();

      const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: entityTypePermissions(entity.type).delete } };
      if (entity.type in AnalyticType) {
        return this.http.delete(`${getServiceUrl('mms')}/sites/${siteId}/analytics/${entity.id}`, options);
      } else if (entity.type in MeasurementType) {
        const taskId = (entity as Measurement).taskId;
        return this.http.delete(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/measurements/${entity.id}`, options);
      } else if (entity.type in AnnotationType) {
        return this.http.delete(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${entity.id}`, options);
      } else if (entity.type in ModelEditType) {
        return this.http.delete(`${getServiceUrl('mms')}/sites/${siteId}/modelEdits/${entity.id}`, options);
      }
    }

    return of(null);
  }

  private serverAddEntity(entity: MapEntity) {
    if ('type' in entity) {
      const siteId = this.siteEntitiesQuery.getSiteId();
      const serverEntity = this.toServerEntity(entity);

      const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: entityTypePermissions(entity.type).create } };
      if (entity.type in AnalyticType) {
        return this.http.post(`${getServiceUrl('mms')}/sites/${siteId}/analytics`, serverEntity, options);
      } else if (entity.type in MeasurementType) {
        const taskId = (entity as Measurement).taskId;
        return this.http.post(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/measurements`, serverEntity, options);
      } else if (entity.type in AnnotationType) {
        return this.http.post(`${getServiceUrl('mms')}/sites/${siteId}/annotations`, serverEntity, options);
      } else if (entity.type in ModelEditType) {
        return this.http.post(`${getServiceUrl('mms')}/sites/${siteId}/modelEdits`, serverEntity, options);
      }
    }

    return of(null);
  }

  serverUpdateEntity(entity: MapEntity) {
    // Can't update new entity
    if (isNewEntity(entity.id)) {
      return of(null);
    }

    if ('type' in entity) {
      const siteId = this.siteEntitiesQuery.getSiteId();
      const serverEntity = this.toServerEntity(entity);

      // All users have update permissions so they can save recalculation of invalid values
      const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: ACCOUNT_USER_ACCESS_LEVELS[0] } };
      if (entity.type in AnalyticType) {
        return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/analytics/${entity.id}`, serverEntity, options);
      } else if (entity.type in MeasurementType) {
        const taskId = (entity as Measurement).taskId;
        return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/measurements/${entity.id}`, serverEntity, options);
      } else if (entity.type in AnnotationType) {
        return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${entity.id}`, serverEntity, options);
      } else if (entity.type in ModelEditType) {
        return this.http.put(`${getServiceUrl('mms')}/sites/${siteId}/modelEdits/${entity.id}`, serverEntity, options);
      }
    }

    return of(null);
  }

  serverSaveEntity(entity: MapEntity, changeVisibility = true) {
    const isEntityNew = isNewEntity(entity.id);
    let updatedEntity = entity;
    if (changeVisibility) {
      const pinned = isEntityNew || this.siteEntitiesQuery.isEntityPinned(entity.id, entity.type);
      updatedEntity = { ...entity, pinned, inEdit: false };
    }

    // Add coordinates field
    const siteCS = this.siteEntitiesQuery.getSiteCoordinateSystem();
    if (siteCS) {
      const coordinates = entity.positions?.map(position => {
        const coordinate = GeoUtils.cartesian3ToDeg(position);
        coordinate.height = 0; // Remove height since it isn't accurate and isn't consistent across flights
        return convertCoordinateToSiteCS(coordinate, siteCS);
      });
      if (coordinates && coordinates.filter(c => !!c).length === entity.positions.length) {
        updatedEntity = { ...updatedEntity, coordinates };
      }
    }

    // Trim spaces from entity name
    updatedEntity = { ...updatedEntity, name: updatedEntity.name?.trim() };

    // first update local Entity
    this.siteEntitiesStore.updateEntity(updatedEntity);

    // Post to the server
    let upsert$: Observable<any> = null;
    if (isEntityNew) {
      upsert$ = this.serverAddEntity(updatedEntity);
    } else {
      upsert$ = this.serverUpdateEntity(updatedEntity);
    }

    return upsert$.pipe(
      map((result: { id: string }) => {
        let entityWithId: MapEntity = updatedEntity;
        if (isEntityNew) {
          // Add entity with ID from server
          entityWithId = { ...updatedEntity, id: result.id, creationTime: new Date() };
          if (!(entityWithId.type in MeasurementType) || (entityWithId as Measurement).taskId === this.siteQuery.getActiveTaskId()) {
            this.siteEntitiesStore.addEntity(entityWithId);
          }
          this.analyticsService.addEntity(entityWithId);
        } else {
          this.siteEntitiesStore.updateEntity(entityWithId);
          this.analyticsService.editEntity(entityWithId);
        }

        return entityWithId;
      })
    );
  }

  private toServerEntity(entity: MapEntity): GetAnalyticResponse | GetMeasurementResponse | GetAnnotationResponse | GetModelEditResponse {
    const data = entity.calcResult?.map((modelValues: CalcModelValues) => ({
      ...modelValues,
      values: modelValues?.values?.reduce((sum, v) => {
        sum[v.key] = v.value;
        return sum;
      }, {})
    }));

    // Remove unnecessary and heavy fields
    entity = { ...entity, geoJson: undefined, calcResult: undefined };

    if (entity.type in AnnotationType) {
      return {
        ...entity,
        attachments: undefined,
        notes: undefined,
        drawings: undefined,
        groupId: entity.groupId || null,
        layerId: entity.layerId || null
      } as GetAnnotationResponse;
    } else if (entity.type in AnalyticType) {
      return {
        ...entity,
        groupId: entity.groupId || null,
        layerId: entity.layerId || null,
        data
      } as GetAnalyticResponse;
    } else if (entity.type in ModelEditType) {
      return {
        ...entity,
        data
      } as GetModelEditResponse;
    } else {
      return {
        ...entity,
        groupId: entity.groupId || null,
        layerId: entity.layerId || null,
        values: data?.[0]?.values
      } as GetMeasurementResponse;
    }
  }

  removeEntities(entities: MapEntity[]) {
    return forkJoin(entities.map(entity => this.removeEntity(entity.id, entity.type)));
  }

  addAnnotationNote(id: string, note: string) {
    const activeUser = this.authQuery.getActiveUserResponse();

    this.siteEntitiesStore.addAnnotationNote(id, {
      note,
      id: generateTempEntityId(),
      createdBy: {
        userId: activeUser.userId,
        firstName: activeUser.firstName,
        lastName: activeUser.lastName
      },
      markedForSave: true
    });
  }

  addAnnotationFiles(id: string, attachments: Array<Partial<AnnotationFile>>) {
    const activeUser = this.authQuery.getActiveUserResponse();

    this.siteEntitiesStore.addAnnotationFiles(
      id,
      attachments.map(attachment => ({
        ...attachment,
        id: generateTempEntityId(),
        uploadedBy: {
          userId: activeUser.userId,
          firstName: activeUser.firstName,
          lastName: activeUser.lastName
        }
      }))
    );
  }

  removeAnnotationFile(id: string, file: AnnotationFile) {
    if (file.markedForSave) {
      this.siteEntitiesStore.removeAnnotationFile(id, file.id);
    } else {
      this.siteEntitiesStore.updateAnnotationFile(id, { ...file, markedForDelete: true });
    }
  }

  updateAnnotationFile(id: string, file: AnnotationFile) {
    this.siteEntitiesStore.updateAnnotationFile(id, file);
  }

  serverSaveAnnotationNote(annotationId: string, note: AnnotationNote) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    return this.http
      .post(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${annotationId}/notes`, note, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationNotes.create }
      })
      .pipe(
        tap((response: { id: string }) => {
          // Remove temp note
          this.siteEntitiesStore.removeAnnotationNote(annotationId, note.id);

          // Re-add note with server generated ID
          this.siteEntitiesStore.addAnnotationNote(annotationId, { ...note, id: response.id, creationTime: new Date() });
        })
      );
  }

  serverDeleteAnnotationNote(annotationId: string, note: AnnotationNote) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${annotationId}/notes/${note.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationNotes.delete }
      })
      .pipe(tap(() => this.siteEntitiesStore.removeAnnotationNote(annotationId, note.id)));
  }

  serverSaveAnnotationFile(annotationId: string, attachment: AnnotationFile) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/annotations/${annotationId}/attachments`, attachment, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationFiles.create }
      })
      .pipe(
        switchMap((response: { id: string; url: string }) => {
          // Remove temp file
          this.siteEntitiesStore.removeAnnotationFile(annotationId, attachment.id);

          // Re-add file with server generated ID
          this.siteEntitiesStore.addAnnotationFiles(annotationId, [{ ...attachment, id: response.id, creationTime: new Date() }]);

          // Upload actual file
          return this.http
            .put(response.url, attachment.file, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationFiles.create } })
            .pipe(
              switchMap(() =>
                this.http.put(
                  `${getServiceUrl('file')}/sites/${siteId}/annotations/${annotationId}/attachments/${response.id}/completeUpload`,
                  null,
                  { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationFiles.create } }
                )
              )
            );
        })
      );
  }

  serverDeleteAnnotationFile(annotationId: string, file: AnnotationFile) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/annotations/${annotationId}/attachments/${file.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.annotationFiles.delete }
      })
      .pipe(tap(() => this.siteEntitiesStore.removeAnnotationFile(annotationId, file.id)));
  }

  serverAddAnnotationDrawings(annotationId: string, drawings: Drawing[]) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    const request: CreateDrawingsRequest = { createDrawingsRequest: drawings };
    return this.http
      .post<CreateDrawingIdsResponse>(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${annotationId}/drawings`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.drawings.create }
      })
      .pipe(
        tap(response => {
          const tempIds = drawings.map(drawing => drawing.id);
          this.siteEntitiesStore.removeAnnotationDrawings(annotationId, tempIds);
          const drawingIds = response.ids;
          const drawingsWithUpdatedId = drawingIds.map((id, index) => ({ ...drawings[index], id, creationTime: new Date() }));
          this.siteEntitiesStore.addAnnotationDrawings(annotationId, drawingsWithUpdatedId);
          this.analyticsService.saveNewDrawings(
            this.siteEntitiesQuery.getEntity(annotationId, AnnotationType.ANNOTATION) as Annotation,
            drawingsWithUpdatedId
          );
        })
      );
  }

  serverUpdateAnnotationDrawings(annotationId: string, drawings: Drawing[]) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    const request: UpdateDrawingsRequest = { updateDrawingsRequest: drawings };
    return this.http
      .put(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${annotationId}/drawings`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.drawings.update }
      })
      .pipe(
        tap(() =>
          this.analyticsService.saveDrawingsEditing(
            this.siteEntitiesQuery.getEntity(annotationId, AnnotationType.ANNOTATION) as Annotation,
            drawings
          )
        )
      );
  }

  serverDeleteAnnotationDrawings(annotationId: string, drawings: Drawing[]) {
    const siteId = this.siteEntitiesQuery.getSiteId();
    const drawingIds = drawings.map(d => d.id);
    const request: DeleteDrawingIdsRequest = { ids: drawingIds };
    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/annotations/${annotationId}/drawings`, {
        body: request,
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.drawings.delete }
      })
      .pipe(
        tap(() => {
          this.analyticsService.saveDrawingsDeletion(
            this.siteEntitiesQuery.getEntity(annotationId, AnnotationType.ANNOTATION) as Annotation,
            drawings
          );
          this.siteEntitiesStore.removeAnnotationDrawings(annotationId, drawingIds);
        })
      );
  }

  private initLocalEntity(entity: Partial<MapEntity>) {
    const localEntity = {
      id: generateTempEntityId(),
      pinned: false,
      inEdit: true,
      groupId: this.siteEntitiesQuery.inferDefaultGroupLayerId(entity, 'groupId'),
      layerId: this.siteEntitiesQuery.inferDefaultGroupLayerId(entity, 'layerId'),
      ...entity
    } as MapEntity;

    if (entity.type in AnnotationType) {
      // Put today's date in UTC
      const now = new Date();
      const creationTime = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
      const activeUser = this.authQuery.getActiveUserResponse();

      return {
        status: AnnotationStatus.OPEN,
        priority: AnnotationPriority.MEDIUM,
        creationTime,
        createdBy: {
          userId: activeUser.userId,
          firstName: activeUser.firstName,
          lastName: activeUser.lastName
        },
        notes: [],
        attachments: [],
        ...localEntity
      } as Annotation;
    }

    return localEntity;
  }

  pinMapEntities(entities: MapEntity[], pinned = true) {
    this.siteEntitiesStore.pinMapEntities(entities, pinned);
    this.analyticsService.displayEntities(entities, pinned);
  }

  pinMapEntitiesById(ids: string[], type: EntityType, pinned = true) {
    if (isDefined(ids)) {
      const entities = ids.map(id => this.siteEntitiesQuery.getEntity(id, type));
      this.pinMapEntities(entities, pinned);
    }
  }

  unpinAllEntities() {
    const pinnedEntities = this.siteEntitiesQuery.getPinnedEntities();
    this.pinMapEntities(pinnedEntities, false);
  }

  async zoomInto(entity: MapEntity, immediate = false) {
    if (!isDefined(entity)) {
      return;
    }

    let positions = [];

    if (isDefined(entity.positions)) {
      positions = entity.positions;
    }

    // If entity is annotation and active we also show drawings and attachment locations
    const activeEntityId = this.siteEntitiesQuery.getActiveEntityId();
    if (entity.type in AnnotationType && activeEntityId === entity.id) {
      const annotation = entity as Annotation;

      if (annotation.hiddenPosition) {
        positions = [];
      }

      if (isDefined(annotation.drawings)) {
        const drawingPositions = annotation.drawings.map(d => d.positions);
        positions = [...positions, ...flatten(drawingPositions)];
      }

      if (isDefined(annotation.attachments)) {
        const attachmentLocations = annotation.attachments
          .filter(attachment => attachment.showOnMap && attachment.longitude && attachment.latitude)
          .map(attachment => Cesium.Cartesian3.fromDegrees(attachment.longitude, attachment.latitude));
        positions = [...positions, ...attachmentLocations];
      }
    }

    return await this.siteMapService.zoomInto(positions, immediate);
  }

  setActiveEditor(activeEditor: PolygonPolylineEditorObservable) {
    const currentEditor = this.siteEntitiesQuery.getActiveEditor();
    if (currentEditor) {
      currentEditor.dispose();
    }

    this.siteEntitiesStore.setActiveEditor(activeEditor);
  }

  setActiveEditorModifying(modified: boolean) {
    this.siteEntitiesStore.setActiveEditorModifying(modified);
  }

  setAddingMarkerForActiveEntity(inAdding: boolean) {
    this.siteEntitiesStore.setAddingMarkerForActiveEntity(inAdding);
  }

  showMapEditorEndDrawingButtons(show: boolean) {
    this.siteEntitiesStore.showMapEditorEndDrawingButtons(show);
  }

  setIsMapEntitySelectionDisabled(isDisabled: boolean) {
    this.siteEntitiesStore.setIsMapEntitySelectionDisabled(isDisabled);
  }

  setActiveEditorDisabled(disabled: boolean) {
    this.siteEntitiesStore.setActiveEditorDisabled(disabled);
  }

  fetchLayers(siteId: string) {
    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/layers`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.layers.read }
      })
      .pipe(
        map((response: GetAllLayersResponse) => [DEFAULT_LAYER, ...(response.layers || [])]),
        tap((layers: Layer[]) => this.siteEntitiesStore.layers.set(layers))
      );
  }

  fetchGroups(siteId: string) {
    return this.http
      .get(`${getServiceUrl('mms')}/sites/${siteId}/groups`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.groups.read }
      })
      .pipe(
        map((response: GetAllGroupsResponse) => [{ id: DEFAULT_GROUP_LAYER_ID, name: 'None' }, ...(response.groups || [])]),
        tap((groups: Group[]) => this.siteEntitiesStore.groups.set(groups))
      );
  }

  createNewLayer(siteId: string, layer: Layer) {
    return this.http
      .post(`${getServiceUrl('mms')}/sites/${siteId}/layers`, layer, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.layers.create }
      })
      .pipe(
        tap((response: { id: string }) => {
          this.siteEntitiesStore.layers.add({ ...layer, id: response.id });
        })
      );
  }

  createNewGroup(siteId: string, group: Group) {
    return this.http
      .post(`${getServiceUrl('mms')}/sites/${siteId}/groups`, group, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.groups.create }
      })
      .pipe(
        tap((response: { id: string }) => {
          this.siteEntitiesStore.groups.add({ ...group, id: response.id });
        })
      );
  }

  updateLayer(siteId: string, layer: Layer) {
    return this.http
      .put(`${getServiceUrl('mms')}/sites/${siteId}/layers/${layer.id}`, layer, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.layers.update }
      })
      .pipe(tap(() => this.siteEntitiesStore.layers.update(layer.id, layer)));
  }

  updateGroup(siteId: string, group: Group) {
    return this.http
      .put(`${getServiceUrl('mms')}/sites/${siteId}/groups/${group.id}`, group, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.groups.update }
      })
      .pipe(tap(() => this.siteEntitiesStore.groups.update(group.id, group)));
  }

  deleteLayer(siteId: string, layerId: string) {
    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/layers/${layerId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.layers.delete }
      })
      .pipe(tap(() => this.updateLayerRemoved(layerId)));
  }

  deleteGroup(siteId: string, groupId: string) {
    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/groups/${groupId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.groups.delete }
      })
      .pipe(tap(() => this.updateGroupRemoved(groupId)));
  }

  private updateLayerRemoved(layerId: string) {
    this.siteEntitiesStore.layers.remove(layerId);

    // Update measurements with deleted layer to default
    this.siteEntitiesStore.analytics.update((entity: Analytic) => entity.layerId === layerId, {
      layerId: DEFAULT_GROUP_LAYER_ID
    });
    this.siteEntitiesStore.measurements.update((entity: Measurement) => entity.layerId === layerId, {
      layerId: DEFAULT_GROUP_LAYER_ID
    });
    this.siteEntitiesStore.annotations.update((entity: Annotation) => entity.layerId === layerId, {
      layerId: DEFAULT_GROUP_LAYER_ID
    });
  }

  private updateGroupRemoved(groupId: string) {
    this.siteEntitiesStore.groups.remove(groupId);

    // Update measurements with deleted group to default
    this.siteEntitiesStore.analytics.update((entity: Analytic) => entity.groupId === groupId, {
      groupId: DEFAULT_GROUP_LAYER_ID
    });
    this.siteEntitiesStore.measurements.update((entity: Measurement) => entity.groupId === groupId, {
      groupId: DEFAULT_GROUP_LAYER_ID
    });
    this.siteEntitiesStore.annotations.update((entity: Annotation) => entity.groupId === groupId, {
      groupId: DEFAULT_GROUP_LAYER_ID
    });
  }

  updateGeojsonChartOptions(entity: MapEntity, chartOptions: Partial<EntityChartOptions>) {
    const feature = entity?.geoJson?.features[0];
    if (!feature) {
      return entity;
    }

    const props = feature.properties as GeoJsonProperties<EntityChartOptions>;
    const prevChartOptions = { ...props?.chartOptions };

    const updatedEntity: MapEntity = {
      ...entity,
      geoJson: {
        ...entity.geoJson,
        features: [
          {
            ...feature,
            properties: {
              ...props,
              chartOptions: {
                ...prevChartOptions,
                ...chartOptions
              }
            }
          }
        ]
      }
    };

    this.updateEntity(entity.id, updatedEntity);

    return updatedEntity;
  }

  updateGeojsonVizOptions(entity: MapEntity, vizOptions: Partial<DeltaElevationVizOptions>) {
    const feature = entity?.geoJson?.features[0];
    if (!feature) {
      return entity;
    }

    const props = feature.properties as GeoJsonProperties<DeltaElevationVizOptions>;
    const prevVizOptions = { ...props?.vizOptions };

    const updatedEntity: MapEntity = {
      ...entity,
      geoJson: {
        ...entity.geoJson,
        features: [
          {
            ...feature,
            properties: {
              ...props,
              vizOptions: {
                ...prevVizOptions,
                ...vizOptions
              }
            }
          }
        ]
      }
    };

    this.updateEntity(entity.id, updatedEntity);

    return updatedEntity;
  }

  hideAllEntities() {
    const predicate = (entity: MapEntity) => entity.pinned && !entity.inEdit;
    this.siteEntitiesStore.analytics.update(predicate, { pinned: false });
    this.siteEntitiesStore.measurements.update(predicate, { pinned: false });
    this.siteEntitiesStore.annotations.update(predicate, { pinned: false });
    this.siteEntitiesStore.modelEdits.update(predicate, { pinned: false });
  }

  async updateCalcResults(entity: MapEntity, onlyActiveTask = false, updateLoading = true) {
    const activeTaskOption: CalcModelOption = { id: this.siteQuery.getActiveTaskId(), type: CalcModelType.TASK };
    const calcModelOptionsToUpdate = onlyActiveTask ? [activeTaskOption] : this.getEntityValidCalcModelOptions(entity);

    // Save geojson viz options before reset
    const geojsonVizOptions = entity?.geoJson?.features?.[0]?.properties?.vizOptions;

    let dataToResetBeforeCalc: Partial<MapEntity> = {
      type: entity.type,
      calcResult: null,
      showMapVizLayer: false,
      geoJson: null
    };

    if (entity.type in EntityTypeWithBaseSurface) {
      if (entity.baseSurface.type === BaseSurfaceType.DESIGN) {
        // update design current version id if was changed
        dataToResetBeforeCalc = {
          ...dataToResetBeforeCalc,
          baseSurface: {
            ...entity.baseSurface,
            id: this.siteDesignsQuery.getCurrentRegularDesignIdByVersionId(entity.baseSurface.id)
          }
        };
      }
    }

    this.updateEntity(entity.id, dataToResetBeforeCalc);
    entity = this.siteEntitiesQuery.getEntity(entity.id, entity.type);

    const entityVertices = entity.positions;
    if (!isDefined(entityVertices)) {
      return;
    }

    updateLoading && this.setCalculationLoading(true);

    const calcService = this.injector.get<CalcService>(CALC_SERVICE_MAPPING[entity.type]);

    let precalcData: PrecalcData;
    if (calcService instanceof PolygonWithSamplesCalcService || calcService instanceof PolylineCalcService) {
      precalcData = calcService.precalc(entity.type in PolygonType ? [...entityVertices, entityVertices[0]] : entityVertices);
    }

    const siteId = this.siteQuery.getSiteId();

    if (!isDefined(calcModelOptionsToUpdate) || calcModelOptionsToUpdate.some(model => model.id === activeTaskOption.id)) {
      const { calcResult, samplePoints, calcStatus } = await calcService.calcResults(entity, siteId, [activeTaskOption], precalcData);
      if (entity.type in EntityTypeWithGeoJson && samplePoints) {
        samplePoints.properties.vizOptions = { ...samplePoints.properties.vizOptions, ...geojsonVizOptions };
        entity = this.updateVizOptionsAfterCalc(entity, samplePoints);
      }

      const updatedFields = {
        dataVersion: getLatestDataVersion(entity.type),
        calcResult,
        calcStatus: calcStatus ?? CalcStatus.SUCCESS,
        showMapVizLayer: true
      };

      this.updateEntity(entity.id, {
        ...entity,
        ...updatedFields
      } as Partial<MapEntity>);
    }

    const calcModelOptionsWithoutActiveTask = calcModelOptionsToUpdate?.filter(model => model.id !== activeTaskOption.id);
    if (entity.type in AnalyticType && isDefined(calcModelOptionsWithoutActiveTask)) {
      this.setDeltaCalculationLoading(true);
      updateLoading && this.setCalculationLoading(false);

      let updatedEntity = this.siteEntitiesQuery.getEntity(entity.id, entity.type);

      const { calcResult, samplePoints, calcStatus } = await calcService.calcResults(
        updatedEntity,
        siteId,
        calcModelOptionsWithoutActiveTask,
        precalcData
      );
      if (updatedEntity.type in EntityTypeWithGeoJson && samplePoints) {
        const updatedGeojsonVizOptions = entity?.geoJson?.features?.[0]?.properties?.vizOptions;
        samplePoints.properties.vizOptions = { ...samplePoints.properties.vizOptions, ...updatedGeojsonVizOptions };
        updatedEntity = this.updateVizOptionsAfterCalc(updatedEntity, samplePoints);
      }

      const updatedFields = {
        dataVersion: getLatestDataVersion(updatedEntity.type),
        calcResult,
        calcStatus: calcStatus ?? CalcStatus.SUCCESS,
        showMapVizLayer: true
      };

      this.updateEntity(updatedEntity.id, {
        ...updatedEntity,
        ...updatedFields
      } as Partial<MapEntity>);

      this.setDeltaCalculationLoading(false);
    } else {
      updateLoading && this.setCalculationLoading(false);
    }
  }

  private updateVizOptionsAfterCalc(entity: MapEntity, samplePoints: Feature) {
    const vizOptions = merge({}, entity?.geoJson?.features?.[0]?.properties?.vizOptions, samplePoints.properties.vizOptions);
    return this.updateGeojsonVizOptions(
      { ...entity, geoJson: featureCollection([samplePoints]) },
      { ...DEFAULT_VIZ_OPTIONS[entity.type], ...vizOptions }
    );
  }

  // if entity already has calcResults for one or more tasks and / or designs - return the list of them for the next recalculation
  private getEntityValidCalcModelOptions(entity: MapEntity): CalcModelOption[] {
    if (!isDefined(entity?.calcResult)) {
      return [];
    }
    const usedEntityCalcModelOptions: CalcModelOption[] = entity.calcResult
      .map(res => {
        if (res.type === CalcModelType.DESIGN) {
          // update design current version if was changed
          const activeDesign = this.siteDesignsQuery.getActiveRegularDesignByVersionId(res.id);
          const currentVersion = activeDesign?.versions.find(v => v.currentVersion);
          return {
            id: currentVersion?.id,
            type: CalcModelType.DESIGN,
            name: this.siteDesignsQuery.getRegularDesignNameWithVersion(activeDesign, currentVersion)
          };
        } else {
          return {
            id: this.siteQuery.getTask(res.id)?.id,
            name: res.name,
            type: res.type
          };
        }
      })
      // invalid id means that design or task was deleted / unsync
      .filter(model => model.id);

    const availableCalcModelOptions: CalcModelOption[] = this.siteQuery.getAllAvailableCalcModelOptions();
    const validEntityCalcModelOptions = usedEntityCalcModelOptions.filter(({ id, type }) =>
      // if task or design not available means it became invalid for some reasons
      availableCalcModelOptions.some(availableOption => availableOption.id === id && availableOption.type === type)
    );

    return !!validEntityCalcModelOptions ? validEntityCalcModelOptions : [];
  }

  private hasTextureData(entity: MapEntity): boolean {
    if (!(entity.type in EntityTypeWithBaseSurface)) {
      return true;
    }

    const props: GeoJsonProperties = entity?.geoJson?.features[0]?.properties as GeoJsonProperties;
    if (!props) {
      return false;
    }

    return (
      !!props.isBboxSamplePointsInsidePolygon &&
      !!props.bboxPointsCountWidth &&
      !!props.bboxPointsCountHeight &&
      !!props.surfaceSamplesHeights &&
      !!props.terrainSamplesValues
    );
  }

  getEntitySampleData(entity: MapEntity, retry = true): Observable<FeatureCollection> {
    const readPermission = entityTypePermissions(entity.type).read;
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: readPermission } };
    return this.getEntitySampleDataUrl(entity, readPermission).pipe(
      switchMap(url => this.http.get<FeatureCollection>(url, options)),
      catchError((error: HttpErrorResponse) => {
        // URL is invalid or expired, try to get it again
        if (retry && error.status === 403) {
          const requestUrl = this.entityDataRequestUrl(entity);
          if (!requestUrl) {
            return of(null);
          }

          return this.http.get(requestUrl, options).pipe(
            switchMap(({ url }: { url: string }) => {
              const updatedEntity = { ...entity, dataUrl: url };
              this.updateEntity(updatedEntity.id, updatedEntity);
              return of(updatedEntity);
            }),
            switchMap((responseEntity: MapEntity) => this.getEntitySampleData(responseEntity, false))
          );
        } else {
          return of(null);
        }
      })
    );
  }

  updateEntityGeoJson(entity: MapEntity, geoJson: FeatureCollection) {
    const requestUrl = this.entityDataRequestUrl(entity);
    if (!requestUrl) {
      return throwError(() => new Error('Unknown entity type'));
    }

    // Get upload URL
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: entityTypePermissions(entity.type).update } };
    return this.http.post(requestUrl, null, options).pipe(
      switchMap(({ url }: { url: string }) =>
        // Upload gzipped samples to S3
        from(gzip(geoJson)).pipe(
          switchMap(gzippedJson =>
            this.http.put(url, gzippedJson, {
              headers: {
                ...options.headers,
                ...GZIP_HEADERS
              }
            })
          )
        )
      ),
      switchMap(() => this.http.get(requestUrl, options)), // Get new URL
      tap(({ url }: { url: string }) => this.updateEntity(entity.id, { id: entity.id, type: entity.type, geoJson, dataUrl: url }))
    );
  }

  private entityDataRequestUrl(entity: MapEntity) {
    if (entity.type in AnalyticType) {
      const siteId = this.siteEntitiesQuery.getSiteId();
      return `${getServiceUrl('mms')}/sites/${siteId}/analytics/${entity.id}/data`;
    } else if (entity.type in MeasurementType) {
      const siteId = this.siteEntitiesQuery.getSiteId();
      const taskId = (entity as Measurement).taskId;
      return `${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/measurements/${entity.id}/data`;
    } else {
      return '';
    }
  }

  private getEntitySampleDataUrl(entity: MapEntity, readPermission: AccessLevelEnum) {
    const requestUrl = this.entityDataRequestUrl(entity);
    if (!requestUrl) {
      return of(null);
    }

    if (entity.dataUrl) {
      return of(entity.dataUrl);
    } else {
      const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: readPermission } };
      return this.http.get(requestUrl, options).pipe(map(({ url }: { url: string }) => url));
    }
  }

  setMeasurementsLoading(loading: boolean) {
    this.siteEntitiesStore.measurements.setLoading(loading);
  }

  showDrawingTools(show: boolean) {
    this.siteEntitiesStore.showDrawingTools(show);
  }

  addDrawings(drawings: Drawing[]) {
    if (isDefined(drawings)) {
      this.siteEntitiesStore.upsertDrawings(drawings);
    }
  }

  setActiveDrawing(id: string) {
    this.siteEntitiesStore.drawings.setActive(id);
  }

  addDrawingEditors(editors: DrawingEditor[]) {
    this.siteEntitiesStore.addDrawingEditors(editors);
  }

  updateDrawing(drawingId: string, drawingDataToUpdate: Partial<Drawing>) {
    this.siteEntitiesStore.drawings.update(drawingId, drawingDataToUpdate);
  }

  updateAllDrawings(drawingDataToUpdate: Partial<Drawing>) {
    this.siteEntitiesStore.drawings.update(null, drawingDataToUpdate);
  }

  removeDrawingsFromStore(drawingIds: string[]) {
    this.siteEntitiesStore.removeEditorsByDrawingIds(drawingIds);
    this.siteEntitiesStore.drawings.remove(drawingIds);
  }

  removeDrawingEditor(drawingId: string) {
    this.siteEntitiesStore.removeEditorsByDrawingIds([drawingId]);
  }

  setActiveDrawingStyleProps(style: StoreDrawingStyleProps) {
    this.siteEntitiesStore.setActiveDrawingStyleProps(style);
  }

  resetActiveDrawingStyleProps() {
    this.siteEntitiesStore.setActiveDrawingStyleProps(DEFAULT_DRAWING_STYLE_PROPS);
  }
}
