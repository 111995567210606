import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';
import { Cesium3DTileFeature, TileProviderError } from 'cesium';
import { differenceBy, groupBy, partition, uniqBy } from 'lodash';
import { combineLatest, forkJoin, merge, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

import { GetFavoriteSessionsResponse } from '../../../../generated/aimanagement/model/getFavoriteSessionsResponse';
import { GetSessionQueriesHistoryResponse } from '../../../../generated/aimanagement/model/getSessionQueriesHistoryResponse';
import { GetSessionsHistoryResponse } from '../../../../generated/aimanagement/model/getSessionsHistoryResponse';
import { CreateCustomFieldRequest } from '../../../../generated/file/model/createCustomFieldRequest';
import { CreateCustomFieldsRequest } from '../../../../generated/file/model/createCustomFieldsRequest';
import { CreateFeatureDataRequest } from '../../../../generated/file/model/createFeatureDataRequest';
import { CreateGeorefernceDesignResponse } from '../../../../generated/file/model/createGeorefernceDesignResponse';
import { DesignIdsRequest } from '../../../../generated/file/model/designIdsRequest';
import { GetAllDesignsResponse } from '../../../../generated/file/model/getAllDesignsResponse';
import { GetAllGeoreferenceDesignsResponse } from '../../../../generated/file/model/getAllGeoreferenceDesignsResponse';
import { GetAllRoadDesignsResponse } from '../../../../generated/file/model/getAllRoadDesignsResponse';
import { GetCustomFieldResponses } from '../../../../generated/file/model/getCustomFieldResponses';
import { GetDesignResponse } from '../../../../generated/file/model/getDesignResponse';
import { GetFeatureDataResponses } from '../../../../generated/file/model/getFeatureDataResponses';
import { GetGeoreferenceDesignResponse } from '../../../../generated/file/model/getGeoreferenceDesignResponse';
import { GetRoadDesignResponse } from '../../../../generated/file/model/getRoadDesignResponse';
import { Page } from '../../../../generated/file/model/page';
import { UpdateFeatureDataRequest } from '../../../../generated/file/model/updateFeatureDataRequest';
import { UpdateFeatureDataResponses } from '../../../../generated/file/model/updateFeatureDataResponses';
import { UpdateFeaturesDataRequest } from '../../../../generated/file/model/updateFeaturesDataRequest';
import { UpdateGeoreferenceDesignPageRequest } from '../../../../generated/file/model/updateGeoreferenceDesignPageRequest';
import { UpdateGeoreferenceDesignRequest } from '../../../../generated/file/model/updateGeoreferenceDesignRequest';
import { UpdateRoadDesignRequest } from '../../../../generated/file/model/updateRoadDesignRequest';
import { UploadDesignRequest } from '../../../../generated/file/model/uploadDesignRequest';
import { UploadDesignResponse } from '../../../../generated/file/model/uploadDesignResponse';
import { UploadRoadDesignRequest } from '../../../../generated/file/model/uploadRoadDesignRequest';
import { UploadRoadDesignResponse } from '../../../../generated/file/model/uploadRoadDesignResponse';
import { CreateFileRequest } from '../../../../generated/integration/model/createFileRequest';
import { CreateFolderRequest } from '../../../../generated/integration/model/createFolderRequest';
import { CreateItemsSyncRequest } from '../../../../generated/integration/model/createItemsSyncRequest';
import { CreateSyncVersionRequest } from '../../../../generated/integration/model/createSyncVersionRequest';
import { GetAllFilesDetailsResponse } from '../../../../generated/integration/model/getAllFilesDetailsResponse';
import { CloudFrontPreSignedPolicy } from '../../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { GetAllCategoriesResponse } from '../../../../generated/tenant/model/getAllCategoriesResponse';
import { AuthQuery } from '../../../auth/state/auth.query';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiPollingService } from '../../../shared/services/api-polling.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { UserSettingsService } from '../../../shared/services/user-settings.service';
import { ResourceType } from '../../../shared/share-resource-dialog/share-resource-dialog.component';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { assertNever, isDefined } from '../../../shared/utils/general';
import { IntegrationEnum, Site } from '../../../tenant/tenant.model';
import { TenantQuery } from '../../../tenant/tenant.query';
import { DesignChatResultFeature } from '../../detailed-site-sidenav/designs/design-ai-chat/design-chat-session/design-chat-session.component';
import { SiteMapService } from '../../services/site-map.service';
import { DesignGeojsonLoadError, DesignsMapManagerService } from './designs-map-manager.service';
import {
  customFieldToLayerProperty,
  CustomPropertyInteractionType,
  Design,
  DesignCategory,
  DesignChatPageToken,
  DesignChatSessionsHistory,
  DesignLayerProperty,
  DesignLayerPropertyType,
  DesignState,
  DesignsUploadingState,
  DesignType,
  DesignVersion,
  GeoReferencedDesign,
  GeoReferencedDesignState,
  getDesignOpacityUserSettingsKey,
  IntegrationDesignNode,
  IntegrationDesignNodeType,
  IntegrationDesignType,
  RegularDesign,
  RoadDesign,
  RoadDesignType,
  RoadDesignValidationError,
  StationNamingFormat
} from './detailed-site-designs.model';
import { DetailedSiteDesignsQuery } from './detailed-site-designs.query';
import { DetailedSiteDesignsStore } from './detailed-site-designs.store';

@Injectable({ providedIn: 'root' })
export class DetailedSiteDesignsService {
  constructor(
    private siteDesignsStore: DetailedSiteDesignsStore,
    private http: HttpClient,
    private designsQuery: DetailedSiteDesignsQuery,
    private designsManager: DesignsMapManagerService,
    private tenantQuery: TenantQuery,
    private userSettingsService: UserSettingsService,
    private apiPoller: ApiPollingService,
    private analyticsService: AnalyticsService,
    private snackbar: SnackBarService,
    private siteMapService: SiteMapService,
    private resourceLinksService: ResourceLinksService,
    private authQuery: AuthQuery
  ) {}

  init(site: Site, viewerCredentials: CloudFrontPreSignedPolicy) {
    this.siteDesignsStore.initStore(site.id, viewerCredentials);

    // Save site units in design manager for unit conversions
    this.designsManager.setSiteUnits(site.units);
  }

  fetchDesignsAndCategories(siteId: string) {
    this.siteDesignsStore.setLoading(true);
    return forkJoin([
      this.fetchDesignCategories(siteId),
      this.fetchSiteRegularDesigns(siteId),
      this.fetchSiteRoadDesigns(siteId),
      this.fetchSiteGeoReferencedDesigns(siteId)
    ]).pipe(finalize(() => this.siteDesignsStore.setLoading(false)));
  }

  private fetchDesignCategories(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/designs/categories`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.read }
      })
      .pipe(
        tap((response: GetAllCategoriesResponse) => {
          if (response && response.categories) {
            this.siteDesignsStore.setDesignCategories(response.categories);
          }
        })
      );
  }

  private fetchSiteRegularDesigns(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/designs`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.read }
      })
      .pipe(
        tap((response: GetAllDesignsResponse) => {
          if (isDefined(response?.designs)) {
            this.siteDesignsStore.upsertRegularDesigns(response.designs.map(this.toLocalDesign));
          }
        })
      );
  }

  private fetchSiteRoadDesigns(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.read }
      })
      .pipe(
        tap((response: GetAllRoadDesignsResponse) => {
          if (isDefined(response?.roadDesigns)) {
            this.siteDesignsStore.upsertRoadDesigns(response.roadDesigns.map(this.toLocalRoadDesign));
          }
        })
      );
  }

  private fetchSiteGeoReferencedDesigns(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.geoReferencedDesigns.read }
      })
      .pipe(
        tap((response: GetAllGeoreferenceDesignsResponse) => {
          if (isDefined(response?.designs)) {
            this.siteDesignsStore.upsertGeoReferencedDesigns(response.designs.map(this.toLocalGeoReferencedDesign));
          }
        })
      );
  }

  private toLocalDesign = (design: GetDesignResponse): RegularDesign => {
    return {
      type: DesignType.REGULAR_DESIGN,
      opacity: this.getDesignOpacity({ id: design.id, type: DesignType.REGULAR_DESIGN }),
      ...design
    };
  };

  private toLocalRoadDesign = (roadDesign: GetRoadDesignResponse): RoadDesign => {
    return {
      type: DesignType.ROAD_DESIGN,
      opacity: this.getDesignOpacity({ id: roadDesign.id, type: DesignType.ROAD_DESIGN }),
      ...roadDesign
    };
  };

  private toLocalGeoReferencedDesign = (geoReferencedDesign: GetGeoreferenceDesignResponse): GeoReferencedDesign => {
    return {
      type: DesignType.GEO_REFERENCED_DESIGN,
      geoReferenceReady: geoReferencedDesign.state === GeoReferencedDesignState.READY,
      opacity: this.getDesignOpacity({ id: geoReferencedDesign.id, type: DesignType.GEO_REFERENCED_DESIGN }),
      ...geoReferencedDesign
    };
  };

  private getDesignOpacity({ id, type }: { id: string; type: DesignType }): number {
    const siteId = this.designsQuery.getSiteId();
    return this.userSettingsService.getSiteUserSetting(siteId, getDesignOpacityUserSettingsKey({ id, type })) ?? 100;
  }

  isDesignStateReady(design: Design) {
    if (design.type === DesignType.GEO_REFERENCED_DESIGN) {
      return design.geoReferenceReady;
    } else {
      return design.jsonReady || design.cesium3DReady;
    }
  }

  isDesignReady(design: Design) {
    return this.isDesignStateReady(design) && !design.loading && !isDefined(design.layers);
  }

  async loadDesign(design: Design | RoadDesign) {
    if (design.state === DesignState.READY && !design.loading && !isDefined(design.layers)) {
      this.updateDesign(design.id, { ...design, loading: true });
      let parsedDesign: Design;

      try {
        const urls =
          design.type === DesignType.GEO_REFERENCED_DESIGN
            ? this.designsQuery.getGeoReferencedDesignUrls(design)
            : this.designsQuery.getDesignUrls(design.id);

        const { layers, bbox } = await this.designsManager.loadDesign(design, urls, this.designLoadError(design));

        parsedDesign = { ...design, layers, bbox, loading: false };
      } catch (error) {
        console.error('Error loading design ' + design.id, error);
        parsedDesign = { ...design, loadingError: true };
      }

      this.updateDesign(design.id, parsedDesign);
    }
  }

  private designLoadError = (design: Design) => (error: TileProviderError | DesignGeojsonLoadError) => {
    let message = 'Error loading design';
    if (error instanceof DesignGeojsonLoadError) {
      message = error.message;
      this.updateDesign(design.id, { ...design, loading: false, allIsShown: false });
    } else {
      this.updateDesign(design.id, { ...design, loadingError: true, loading: false, allIsShown: false, layers: null });
      this.designsManager.removeDesign(design.id);
    }
    this.snackbar.openError(message, { design, error });
  };

  uploadDesigns(files: { file: File; name: string }[], categoryId?: string, hasSurface?: boolean, description?: string) {
    const totalSize = files.reduce((size, f) => size + f.file.size, 0);
    this.setDesignsUploadingState({ totalFiles: files.length, totalSize });

    const uploadedSizeMapping = files.reduce(
      (mapping, f) => {
        mapping[f.name] = { size: 0, done: false };
        return mapping;
      },
      {} as { [name: string]: { size: number; done?: boolean } }
    );

    const updateUploadingState = (data: { file: File; loaded?: number; done?: boolean }) => {
      const { file, loaded, done } = data;
      uploadedSizeMapping[file.name] = { size: done ? file.size : loaded, done };

      this.setDesignsUploadingState({
        uploadedSize: Object.values(uploadedSizeMapping).reduce((sum, data) => sum + data.size, 0),
        uploadedFiles: Object.values(uploadedSizeMapping).filter(data => data.done).length
      });
    };

    return forkJoin(
      files.map(f =>
        this.uploadDesign(f.file, f.name, categoryId, hasSurface, files.length > 1, updateUploadingState, description).pipe(
          catchError(error => {
            return throwError(() => ({ ...error, fileName: f.name }));
          })
        )
      )
    ).pipe(finalize(() => this.setDesignsUploadingState(null)));
  }

  private uploadDesign(
    file: File,
    name: string,
    categoryId: string,
    hasSurface: boolean,
    isMultiFileUpload: boolean,
    updateUploadingState: (data: { file: File; loaded?: number; done?: boolean }) => void,
    description: string
  ) {
    const siteId = this.designsQuery.getSiteId();
    const designParams: UploadDesignRequest = { name, categoryId, fileName: file.name, hasSurface, description };

    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/designs`, designParams, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.create }
      })
      .pipe(
        switchMap((designResponse: UploadDesignResponse) => {
          const designId = designResponse.id;
          const uploadUrl = designResponse.url;

          return this.http
            .put(uploadUrl, file, {
              reportProgress: true,
              observe: 'events',
              headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.create }
            })
            .pipe(
              tap(resp => {
                if (resp.type === HttpEventType.UploadProgress) {
                  updateUploadingState({ file, loaded: resp.loaded });
                } else if (resp.type === HttpEventType.Response) {
                  updateUploadingState({ file, done: true });
                }
              }),
              filter(resp => resp.type === HttpEventType.Response),
              switchMap(() => {
                return this.http.put(`${getServiceUrl('file')}/sites/${siteId}/designs/${designId}/completeUpload`, true, {
                  headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.create }
                });
              }),
              tap(() => {
                const design: RegularDesign = {
                  type: DesignType.REGULAR_DESIGN,
                  name: designParams.name,
                  categoryId,
                  id: designId,
                  siteId,
                  opacity: 100,
                  state: DesignState.PROCESSING,
                  fileName: file.name,
                  description,
                  hasSurface,
                  versions: [
                    {
                      activeVersion: true,
                      creationTime: new Date(),
                      id: designId,
                      name: 'V1',
                      state: DesignState.PROCESSING,
                      uploadedBy: this.authQuery.getActiveUserResponse()
                    }
                  ]
                };
                this.siteDesignsStore.upsertRegularDesigns([design]);
                this.analyticsService.importRegularDesign(design, isMultiFileUpload);
              })
            );
        })
      );
  }

  public uploadGeoReferencedDesign(data: {
    file: File;
    name: string;
    categoryId: string;
    description: string;
    fileName: string;
    pages: Page[];
  }) {
    const siteId = this.designsQuery.getSiteId();

    this.setDesignsUploadingState({
      totalFiles: 1,
      totalSize: data.file.size
    });

    const permission = PERMISSIONS.geoReferencedDesigns.create;
    const headers = { [REQUIRED_ACCESS_LEVEL_HEADER]: permission };

    return this.http
      .post(
        `${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns`,
        {
          name: data.name,
          fileName: data.fileName,
          categoryId: data.categoryId,
          description: data.description,
          pages: data.pages
        },
        { headers }
      )
      .pipe(
        switchMap((response: CreateGeorefernceDesignResponse) => {
          const designId = response.id;
          const uploadUrl = response.geoReferenceUrl;

          return this.http
            .put(uploadUrl, data.file, {
              reportProgress: true,
              observe: 'events',
              headers
            })
            .pipe(
              tap(resp => {
                if (resp.type === HttpEventType.UploadProgress) {
                  this.setDesignsUploadingState({
                    uploadedSize: resp.loaded,
                    uploadedFiles: 0
                  });
                } else if (resp.type === HttpEventType.Response) {
                  this.setDesignsUploadingState({
                    uploadedSize: data.file.size,
                    uploadedFiles: 1
                  });
                }
              }),
              filter(resp => resp.type === HttpEventType.Response),
              switchMap(() => {
                return this.http.put(`${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns/${designId}/completeUpload`, true, {
                  headers
                });
              }),
              tap(() => {
                this.setDesignsUploadingState(null);
              }),
              switchMap(() =>
                this.http.get<GetGeoreferenceDesignResponse>(`${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns/${designId}`, {
                  headers
                })
              ),
              tap(response => {
                const design: GeoReferencedDesign = this.toLocalGeoReferencedDesign(response);
                this.siteDesignsStore.upsertGeoReferencedDesigns([design]);
                this.analyticsService.importGeoReferencedDesign(design);
              }),
              catchError(error => {
                this.setDesignsUploadingState(null);
                return throwError(() => error);
              })
            );
        }),
        catchError(error => {
          this.setDesignsUploadingState(null);
          return throwError(() => error);
        })
      );
  }

  uploadRoadDesign(
    name: string,
    categoryId: string,
    roadDesignType: RoadDesignType,
    files: { file1: File; file2: File; file3: File },
    surfaceDesignFields?: { startStation: string; stationInterval: number; stationNamingFormat: StationNamingFormat }
  ) {
    const file1Name = files.file1.name;
    const file2Name = files.file2.name;
    const file3Name = files.file3.name;
    const requestParams: UploadRoadDesignRequest = {
      name,
      categoryId,
      roadDesignType,
      file1Name,
      file2Name,
      file3Name,
      startStation: surfaceDesignFields?.startStation,
      stationInterval: surfaceDesignFields?.stationInterval,
      stationNamingFormatType: surfaceDesignFields?.stationNamingFormat,
      roadName: 'TODO' // change to actual name when field is in form
    };

    this.setDesignsUploadingState({
      totalFiles: 3,
      totalSize: files.file1.size + files.file2.size + files.file3.size
    });

    const siteId = this.designsQuery.getSiteId();
    const permission = PERMISSIONS.roadDesigns.create;
    const headers = { [REQUIRED_ACCESS_LEVEL_HEADER]: permission };
    return this.http.post(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, requestParams, { headers }).pipe(
      switchMap((response: UploadRoadDesignResponse) => {
        const roadDesignId = response.id;
        const uploadedSizeMapping = Object.keys(files).reduce(
          (mapping, fileType) => {
            mapping[fileType] = { size: 0, done: false };
            return mapping;
          },
          {} as { [fileType: string]: { size: number; done: boolean } }
        );

        return forkJoin(
          Object.keys(files).map(fileType =>
            this.http
              .put(response.inputFiles[fileType + 'Url'], files[fileType], { reportProgress: true, observe: 'events', headers })
              .pipe(
                tap(resp => {
                  if (resp.type === HttpEventType.UploadProgress) {
                    uploadedSizeMapping[fileType].size = resp.loaded;
                  } else if (resp.type === HttpEventType.Response) {
                    uploadedSizeMapping[fileType].size = files[fileType].size;
                    uploadedSizeMapping[fileType].done = true;
                  }
                  this.setDesignsUploadingState({
                    uploadedSize: Object.values(uploadedSizeMapping).reduce((sum, data) => sum + data.size, 0),
                    uploadedFiles: Object.values(uploadedSizeMapping).filter(data => data.done).length
                  });
                })
              )
          )
        ).pipe(
          switchMap(() => {
            if (roadDesignType === RoadDesignType.SURFACE) {
              return this.http
                .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/completeSurfaceUpload`, true, { headers })
                .pipe(
                  tap(() => this.setDesignsUploadingState(null)),
                  switchMap(() =>
                    this.http.get<GetRoadDesignResponse>(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}`, {
                      headers
                    })
                  ),
                  tap((roadDesign: GetRoadDesignResponse) => {
                    const localRoadDesign = this.toLocalRoadDesign(roadDesign);
                    this.siteDesignsStore.upsertRoadDesigns([localRoadDesign]);
                    this.analyticsService.importRoadDesign(localRoadDesign);
                  })
                );
            } else {
              // Poll for road design validation runs after calling complete upload
              return this.http
                .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/completeUpload`, true, { headers })
                .pipe(
                  tap(() => this.setDesignsUploadingState({ validating: true })),
                  switchMap(() =>
                    this.apiPoller.poll<GetRoadDesignResponse>(
                      `${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}`,
                      permission,
                      5000,
                      true
                    )
                  ),
                  filter((roadDesign: GetRoadDesignResponse) => roadDesign.state !== DesignState.PROCESSING),
                  tap((roadDesign: GetRoadDesignResponse) => {
                    const localRoadDesign = this.toLocalRoadDesign(roadDesign);
                    if (roadDesign.state === DesignState.VALIDATIONFAILED) {
                      throw new RoadDesignValidationError(localRoadDesign);
                    } else {
                      this.siteDesignsStore.upsertRoadDesigns([localRoadDesign]);
                      this.analyticsService.importRoadDesign(localRoadDesign);
                    }
                  }),
                  catchError(error => {
                    // Delete road design validation error occured
                    if (error instanceof RoadDesignValidationError) {
                      this.deleteRoadDesign(error.roadDesign).subscribe({
                        error: error => {
                          this.snackbar.openError('Error deleting road design', error);
                        }
                      });
                    }
                    return throwError(() => error);
                  })
                );
            }
          })
        );
      }),
      finalize(() => this.setDesignsUploadingState(null))
    );
  }

  setDesignsUploadingState(designsUploading: Partial<DesignsUploadingState>) {
    this.siteDesignsStore.setDesignsUploadingState(designsUploading);
  }

  startDesignsPolling(pollingFreq?: number) {
    const siteId = this.designsQuery.getSiteId();
    const requests: Observable<any>[] = [];

    requests.push(
      this.apiPoller.poll(`${getServiceUrl('file')}/sites/${siteId}/designs`, PERMISSIONS.regularDesigns.read, pollingFreq, true).pipe(
        map((response: GetAllDesignsResponse) => response?.designs),
        tap((designs: GetDesignResponse[]) => {
          const currentSiteId = this.designsQuery.getSiteId();
          if (currentSiteId === siteId && isDefined(designs)) {
            // Find removed designs and update resource links
            const prevDesigns = this.designsQuery.getAllRegularDesigns();
            const removedDesigns = differenceBy(prevDesigns, designs, 'id');
            removedDesigns.forEach(design => this.removeRegularDesign(design));

            this.siteDesignsStore.upsertRegularDesigns(designs.map(this.toLocalDesign));
          }
        })
      )
    );

    requests.push(
      this.apiPoller.poll(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, PERMISSIONS.roadDesigns.read, pollingFreq, true).pipe(
        map((response: GetAllRoadDesignsResponse) => response?.roadDesigns),
        tap((roadDesigns: GetRoadDesignResponse[]) => {
          const currentSiteId = this.designsQuery.getSiteId();
          if (currentSiteId === siteId && isDefined(roadDesigns)) {
            // Find removed road designs and update resource links
            const prevRoadDesigns = this.designsQuery.getAllRoadDesigns();
            const removedRoadDesigns = differenceBy(prevRoadDesigns, roadDesigns, 'id');
            removedRoadDesigns.forEach(roadDesign => this.resourceLinksService.removeResource(roadDesign.id, ResourceLinkType.ROADDESIGN));

            this.siteDesignsStore.upsertRoadDesigns(roadDesigns.map(this.toLocalRoadDesign));
          }
        })
      )
    );

    requests.push(
      this.apiPoller
        .poll(`${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns`, PERMISSIONS.geoReferencedDesigns.read, pollingFreq, true)
        .pipe(
          map((response: GetAllGeoreferenceDesignsResponse) => response?.designs),
          tap((designs: GetGeoreferenceDesignResponse[]) => {
            const currentSiteId = this.designsQuery.getSiteId();
            if (currentSiteId === siteId && isDefined(designs)) {
              // Find removed designs and update resource links
              const prevDesigns = this.designsQuery.getAllGeorefenceDesigns();
              const removedDesigns = differenceBy(prevDesigns, designs, 'id');
              removedDesigns.forEach(design => this.removeGeoReferencedDesign(design));

              this.siteDesignsStore.upsertGeoReferencedDesigns(designs.map(this.toLocalGeoReferencedDesign));
            }
          })
        )
    );

    return merge(...requests);
  }

  createDesignCategory(name: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .post(
        `${getServiceUrl('file')}/sites/${siteId}/designs/categories`,
        { name, siteId },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.create } }
      )
      .pipe(
        map((result: { id: string }) => {
          if (result) {
            return { name, siteId, id: result.id } as DesignCategory;
          }
        }),
        tap((category: DesignCategory) => category && this.siteDesignsStore.addDesignCategory(category))
      );
  }

  editDesignCategory(categoryId: string, name: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .put(
        `${getServiceUrl('file')}/sites/${siteId}/designs/categories/${categoryId}`,
        { name },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.update } }
      )
      .pipe(
        map(() => ({ name, siteId, id: categoryId }) as DesignCategory),
        tap((category: DesignCategory) => this.siteDesignsStore.updateDesignCategory(category))
      );
  }

  deleteDesignCategory(categoryId: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/designs/categories/${categoryId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.designCategories.remove(categoryId);

          // Update all designs in category to have no category. No need to update backend since it is done automatically
          this.siteDesignsStore.regularDesigns.update(
            (design: RegularDesign) => design.categoryId === categoryId,
            (design: RegularDesign) => ({
              categoryId: null,
              inTempCategory: design.sync // show sync design in temp category after perm category deletion
            })
          );
          this.siteDesignsStore.roadDesigns.update((roadDesign: RoadDesign) => roadDesign.categoryId === categoryId, {
            categoryId: null
          });
        })
      );
  }

  async hideAllDesigns() {
    const visibleDesigns = this.designsManager.getVisibleDesigns();
    await Promise.all(visibleDesigns.map(design => this.setDesignShowAll(design.id, design.type, false)));
  }

  async setDesignLayersShow(designId: string, type: DesignType, selectedLayersIds: Set<string>) {
    this.updateDesign(designId, { type, loading: true });
    await this.designsManager.showLayers(designId, selectedLayersIds);
    this.siteDesignsStore.setDesignLayers(designId, type, null, selectedLayersIds);
    this.updateDesign(designId, { type, loading: false });
  }

  setDesignLayersExpanded(designId: string, type: DesignType, layers: Set<string>, expanded: boolean) {
    const designLayers = this.designsQuery.getDesignByType(type, designId)?.layers;

    if (!isDefined(designLayers)) {
      return;
    }

    const updatedLayers = designLayers.map(layer => {
      if (layers.has(layer.id)) {
        return { ...layer, expanded };
      } else {
        return { ...layer, expanded: false };
      }
    });

    this.updateDesign(designId, { type, layers: updatedLayers });
  }

  setDesignOpacity(designId: string, type: DesignType, opacity: number) {
    this.designsManager.setDesignOpacity(designId, opacity);
    this.updateDesign(designId, { type, opacity });
  }

  async setDesignShowAll(designId: string, type: DesignType, show: boolean) {
    const design = this.designsQuery.getDesignByType(type, designId);

    // Load design if not already loaded
    if (show && !this.designsManager.isDesignLoaded(designId)) {
      await this.loadDesign(design);
    }

    if (show) {
      this.analyticsService.displayDesign(design);
    }

    this.updateDesign(designId, { type, loading: true });
    await this.designsManager.showAllLayers(designId, show);
    this.siteDesignsStore.setDesignLayers(designId, type, show);
    this.updateDesign(designId, { type, loading: false });
  }

  deleteRegularDesign(regularDesign: RegularDesign) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/designs/${regularDesign.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.delete }
      })
      .pipe(
        tap(() => {
          this.removeRegularDesign(regularDesign);
          this.analyticsService.deleteRegularDesign(regularDesign);
        })
      );
  }

  editRegularDesign(regularDesign: RegularDesign, dataToUpdate: Partial<RegularDesign>, inTempCategory = false) {
    const siteId = this.designsQuery.getSiteId();
    this.analyticsService.updateRegularDesign(regularDesign);

    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/designs/${regularDesign.id}`, dataToUpdate, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.update }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.updateRegularDesign(regularDesign.id, { id: regularDesign.id, ...dataToUpdate, inTempCategory });
        })
      );
  }

  editDesignsCategory(categoryId: string, designIds: string[]): Observable<RegularDesign[]> {
    const siteId = this.designsQuery.getSiteId();
    const body: DesignIdsRequest = { ids: designIds };

    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/designs/categories/${categoryId}/updateDesignsCategory`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.update }
      })
      .pipe(
        map((resp: GetAllDesignsResponse) => resp.designs.map(this.toLocalDesign)),
        tap(designs => {
          this.siteDesignsStore.upsertRegularDesigns(designs);
        })
      );
  }

  changeDesignActiveVersion(activeVersion: RegularDesign, newActiveVersionId: string, isExpanded: boolean) {
    const siteId = this.designsQuery.getSiteId();
    const inTempCategory = activeVersion.inTempCategory;
    const allIsShown = activeVersion.allIsShown;
    const shownLayers = activeVersion.layers?.filter(layer => layer.show);

    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/designs/${newActiveVersionId}/setActiveVersion`, true, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.synchronizedDesigns.update }
      })
      .pipe(
        switchMap(() => this.fetchRegularDesign(newActiveVersionId)),
        tap(design => {
          this.removeRegularDesign(activeVersion);
          this.updateDesign(design.id, {
            type: design.type,
            inTempCategory,
            allIsShown
          });
        }),
        switchMap(design => {
          if (allIsShown) {
            return this.setDesignShowAll(design.id, design.type, true).then(() => design);
          } else if (isDefined(shownLayers) || isExpanded) {
            return this.loadDesign(design)
              .then(() => {
                const layers = this.designsQuery.getRegularDesign(design.id).layers.map(layer => {
                  const shownLayer = shownLayers.find(shownLayer => shownLayer.id === layer.id);
                  return isDefined(shownLayer)
                    ? {
                        ...layer,
                        show: shownLayer.show,
                        expanded: shownLayer.expanded,
                        expandable: shownLayer.expandable
                      }
                    : layer;
                });
                this.updateDesign(design.id, { layers, type: design.type });
                return {
                  ...design,
                  layers
                };
              })
              .then(design =>
                this.setDesignLayersShow(
                  design.id,
                  design.type,
                  new Set(design.layers.filter(layer => layer.show).map(layer => layer.id))
                ).then(() => design)
              );
          } else {
            return of(design);
          }
        })
      );
  }

  fetchRegularDesign(id: string): Observable<RegularDesign> {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/designs/${id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.regularDesigns.read }
      })
      .pipe(
        map((design: GetDesignResponse) => ({ ...design, type: DesignType.REGULAR_DESIGN as const })),
        tap(design => this.siteDesignsStore.addRegularDesign(design))
      );
  }

  removeRegularDesign(regularDesign: RegularDesign) {
    this.resourceLinksService.removeResource(regularDesign.id, ResourceLinkType.DESIGN);
    this.siteDesignsStore.deleteRegularDesign(regularDesign.id);
    this.designsManager.removeDesign(regularDesign.id);
  }

  removeGeoReferencedDesign(design: GeoReferencedDesign) {
    this.resourceLinksService.removeResource(design.id, ResourceLinkType.DESIGN);
    this.siteDesignsStore.deleteGeoReferencedDesign(design.id);
    this.designsManager.removeDesign(design.id);
  }

  syncRegularDesignVersion(regularDesign: RegularDesign, versionToSync: DesignVersion, integration: IntegrationEnum) {
    const siteId = this.designsQuery.getSiteId();
    const body: CreateSyncVersionRequest = {
      activeDesignId: regularDesign.id,
      externalId: versionToSync.externalId
    };

    return this.http.post(`${getServiceUrl('integration')}/filesIntegration/sites/${siteId}/${integration}/syncVersion`, body, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.synchronizedDesigns.update }
    });
  }

  editRoadDesign(roadDesign: RoadDesign, name: string, categoryId: string) {
    const siteId = this.designsQuery.getSiteId();
    const body: UpdateRoadDesignRequest = { name, categoryId };

    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesign.id}`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.update }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.updateRoadDesign(roadDesign.id, { id: roadDesign.id, ...body });
          this.analyticsService.updateRoadDesign(roadDesign);
        })
      );
  }

  editGeoReferencedDesign(request: UpdateGeoreferenceDesignRequest & UpdateGeoreferenceDesignPageRequest & { id: string }) {
    const siteId = this.designsQuery.getSiteId();
    const requests: Observable<any>[] = [];

    this.analyticsService.updateGeoReferencedDesign(request as any);

    const updateGeoreferenceDesignRequest: UpdateGeoreferenceDesignRequest = {
      categoryId: request.categoryId,
      description: request.description,
      name: request.name
    };

    const updateDesign$ = this.http.put<any>(
      `${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns/${request.id}`,
      updateGeoreferenceDesignRequest,
      {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.geoReferencedDesigns.update }
      }
    );

    requests.push(updateDesign$);

    if (isDefined(request.pages)) {
      const updateGeoreferenceDesignPageRequest: UpdateGeoreferenceDesignPageRequest = {
        pages: request.pages
      };

      const updatePages$ = this.http.put<any>(
        `${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns/pages/${request.id}`,
        updateGeoreferenceDesignPageRequest,
        {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.geoReferencedDesigns.update }
        }
      );

      requests.push(updatePages$);
    }

    return forkJoin(requests).pipe(
      tap(() => {
        this.siteDesignsStore.updateGeoReferencedDesign(request.id, request);
      })
    );
  }

  deleteRoadDesign(roadDesign: RoadDesign) {
    const siteId = this.designsQuery.getSiteId();
    this.analyticsService.deleteRoadDesign(roadDesign);

    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesign.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.deleteRoadDesign(roadDesign.id);
          this.designsManager.removeDesign(roadDesign.id);
          this.resourceLinksService.removeResource(roadDesign.id, ResourceLinkType.ROADDESIGN);
        })
      );
  }

  deleteGeoReferencedDesign(geoReferencedDesign: GeoReferencedDesign) {
    const siteId = this.designsQuery.getSiteId();

    this.analyticsService.deleteGeoReferencedDesign(geoReferencedDesign);

    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/dereferenceDesigns/${geoReferencedDesign.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.geoReferencedDesigns.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.deleteGeoReferencedDesign(geoReferencedDesign.id);
          this.designsManager.removeDesign(geoReferencedDesign.id);
          this.resourceLinksService.removeResource(geoReferencedDesign.id, ResourceLinkType.DESIGN);
        })
      );
  }

  updateDesign(id: string, design: Partial<Design> & { type: DesignType }) {
    switch (design.type) {
      case DesignType.REGULAR_DESIGN:
        this.siteDesignsStore.updateRegularDesign(id, design);
        break;
      case DesignType.ROAD_DESIGN:
        this.siteDesignsStore.updateRoadDesign(id, design);
        break;
      case DesignType.GEO_REFERENCED_DESIGN:
        this.siteDesignsStore.updateGeoReferencedDesign(id, design);
        break;
      default:
        assertNever(design);
    }
  }

  async toggleDesignProjectionView(designId: string, type: DesignType, isGeoJsonEntitiesMode: boolean) {
    this.updateDesign(designId, { type, isGeoJsonEntitiesMode: !isGeoJsonEntitiesMode, loading: true });
    await this.designsManager.setDesignProjectionView(designId, !isGeoJsonEntitiesMode);
    this.updateDesign(designId, { type, loading: false });
  }

  generateElementProgressReport(taskId: string, designId: string) {
    if (!this.tenantQuery.getElectricPolesReportFeatureFlag()) {
      return;
    }

    const siteId = this.designsQuery.getSiteId();
    return this.http.put(`${getServiceUrl('fms')}/tasks/${siteId}/designs/${designId}/tasks/${taskId}/startElectricAIBatch`, true);
  }

  setActiveDesignLayerProperties(properties: DesignLayerProperty[]) {
    this.siteDesignsStore.setActiveDesignLayerProperties(properties);
  }

  setSiteOffset(offset: number) {
    this.designsManager.setSiteOffset(offset);
  }

  addDownloadedDesignToAnalytics(design: Design) {
    this.analyticsService.downloadDesignFile(design);
  }

  resetStore() {
    this.designsManager.clear();
    this.siteDesignsStore.reset();
  }

  updateExpandedDesignIDs(expandedDesignID: string, wasExpanded: boolean) {
    const expandedDesignIDs = new Set(this.designsQuery.getExpandedDesignIDs());
    if (wasExpanded) {
      expandedDesignIDs.delete(expandedDesignID);
    } else {
      expandedDesignIDs.add(expandedDesignID);
    }
    this.siteDesignsStore.updateExpandedDesignIDs(expandedDesignIDs);
  }

  async zoomIntoDesign(design: Design) {
    if (!design) {
      return;
    }

    if (!design.bbox) {
      await this.loadDesign(design);
      switch (design.type) {
        case DesignType.REGULAR_DESIGN:
          design = this.designsQuery.getRegularDesign(design.id);
          break;
        case DesignType.ROAD_DESIGN:
          design = this.designsQuery.getRoadDesign(design.id);
          break;
        case DesignType.GEO_REFERENCED_DESIGN:
          design = this.designsQuery.getGeoReferencedDesign(design.id);
          break;
        default:
          assertNever(design);
      }
    }

    if (!design.bbox || design.bbox.some(n => !isFinite(n))) {
      return;
    }

    const [minX, minY, maxX, maxY] = design.bbox;
    let positions: Cartesian3[];
    if (minX === maxX && minY === maxY) {
      positions = [Cesium.Cartesian3.fromDegrees(minX, minY)];
    } else {
      positions = [
        Cesium.Cartesian3.fromDegrees(minX, minY),
        Cesium.Cartesian3.fromDegrees(maxX, minY),
        Cesium.Cartesian3.fromDegrees(maxX, maxY),
        Cesium.Cartesian3.fromDegrees(minX, maxY)
      ];
    }
    await this.siteMapService.zoomInto(positions);
  }

  setLayerFeaturesSelection(features: Cesium3DTileFeature[]) {
    this.designsManager.setLayerFeaturesSelection(features);
  }

  clearSelectedLayerFeatures() {
    this.designsManager.clearSelectedLayerFeatures();
  }

  setDesignActiveLayer(designId: string, layerId?: string, childLayers?: string[], layerName?: string) {
    this.clearSelectedLayerFeatures();
    this.siteDesignsStore.setDesignActiveLayer(designId, layerId, childLayers, layerName);
    if (!isDefined(designId)) {
      this.siteDesignsStore.setActiveDesignLayerProperties(null);
    }
  }

  fetchIntegrationDesignNodes(integration: IntegrationEnum) {
    const siteId = this.designsQuery.getSiteId();
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designIntegration } };
    return this.http.get(`${getServiceUrl('integration')}/filesIntegration/sites/${siteId}/${integration}`, options).pipe(
      map((resp: GetAllFilesDetailsResponse) => {
        const allNodes: IntegrationDesignNode[] = [
          ...resp.getFolderResponseList?.map(folder => ({
            ...folder,
            type: IntegrationDesignNodeType.FOLDER,
            originalSync: folder.sync
          })),
          ...resp.getFileResponseList?.map(file => {
            return {
              ...file,
              type: IntegrationDesignNodeType.FILE,
              designType: file.designType || IntegrationDesignType.CAD,
              originalSync: file.sync,
              originalDesignType: file.designType || IntegrationDesignType.CAD
            };
          })
        ];
        return allNodes;
      }),
      tap((resp: IntegrationDesignNode[]) => {
        this.siteDesignsStore.upsertIntegrationDesignNodes(resp);
      })
    );
  }

  fetchSyncIntegrationDesignNodesAndAncestors(integration: IntegrationEnum): Observable<IntegrationDesignNode[]> {
    const siteId = this.designsQuery.getSiteId();
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.synchronizedDesigns.read } };
    return this.http
      .get(`${getServiceUrl('integration')}/filesIntegration/sites/${siteId}/${integration}/syncedNodesAndAncestors`, options)
      .pipe(
        map((resp: GetAllFilesDetailsResponse) => {
          return [
            ...resp.getFolderResponseList?.map(folder => ({
              ...folder,
              type: IntegrationDesignNodeType.FOLDER
            })),
            ...resp.getFileResponseList?.map(file => {
              return {
                ...file,
                type: IntegrationDesignNodeType.FILE
              };
            })
          ];
        })
      );
  }

  serverUpdateIntegrationDesignNodes(nodesToUpdate: IntegrationDesignNode[], integration: IntegrationEnum) {
    const siteId = this.designsQuery.getSiteId();

    const groups = groupBy(nodesToUpdate, 'type');
    const createFileRequestList: CreateFileRequest[] = groups[IntegrationDesignNodeType.FILE]?.map(file => ({
      externalId: file.externalId,
      designType: file.designType,
      sync: file.sync
    }));
    const createFolderRequestsList: CreateFolderRequest[] = groups[IntegrationDesignNodeType.FOLDER]?.map(folder => ({
      externalId: folder.externalId,
      sync: folder.sync
    }));
    const request: CreateItemsSyncRequest = { createFileRequestList, createFolderRequestsList };
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designIntegration } };
    return this.http.put<CreateItemsSyncRequest>(
      `${getServiceUrl('integration')}/filesIntegration/sites/${siteId}/${integration}/SyncProviderWithDatubim`,
      request,
      options
    );
  }

  updateIntegrationDesignNodes(nodeIds: string[], dataToUpdate: Partial<IntegrationDesignNode>) {
    if (!isDefined(nodeIds)) {
      return;
    }
    this.siteDesignsStore.integrationDesignNodes.update(nodeIds, dataToUpdate);
  }

  setActiveIntegrationDesignNodes(ids: string[]) {
    this.siteDesignsStore.integrationDesignNodes.setActive(ids);
  }

  getLayerProperties(designId: string, layerId: string, isLeaf: boolean) {
    const designMetaDateProperties = this.designsManager.getDesignLayerMetaDataProperties(designId);
    const siteCustomProperties = this.fetchSiteCustomProperties();
    const layerProperties = this.fetchLayerProperties(designId, layerId);
    const design = this.designsQuery.getRegularDesign(designId);

    this.analyticsService.showRegularDesignFeatureProperties(design);

    return combineLatest([layerProperties, siteCustomProperties]).pipe(
      map(([propertiesResult, siteCustomPropertiesResult]) => {
        const [layerCustomProperties, LayerMetaDataProperties] = partition(
          propertiesResult,
          prop => prop.fieldType === DesignLayerPropertyType.CUSTOM
        );
        const mergedCustomProperties = uniqBy([...layerCustomProperties, ...siteCustomPropertiesResult], 'fieldId');
        const layerProperties = isLeaf
          ? [...mergedCustomProperties, ...uniqBy([...LayerMetaDataProperties, ...designMetaDateProperties], 'name')]
          : [...mergedCustomProperties, ...LayerMetaDataProperties];

        return layerProperties;
      })
    );
  }

  private fetchLayerProperties(designId: string, layerId: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .get(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, {
        params: { featureId: layerId },
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.read }
      })
      .pipe(map((result: GetFeatureDataResponses) => result?.featureDataResponses));
  }

  private fetchSiteCustomProperties() {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .get(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.read }
      })
      .pipe(map((result: GetCustomFieldResponses) => customFieldToLayerProperty(result?.customFieldResponses)));
  }

  selectLayerFeatures(designId: string) {
    const features = this.designsManager.getLayerFeatures(designId);

    if (isDefined(features)) {
      this.setLayerFeaturesSelection(features);
    } else {
      this.clearSelectedLayerFeatures();
    }
  }

  createNewSiteCustomField(customProperty: DesignLayerProperty) {
    const siteId = this.designsQuery.getSiteId();
    const site = this.tenantQuery.getSite(siteId);
    const property: CreateCustomFieldRequest[] = [{ name: customProperty.name, valueType: customProperty.valueType }];
    const request: CreateCustomFieldsRequest = { requestList: property };

    this.analyticsService.siteCustomPropertiesInteraction(site, property, CustomPropertyInteractionType.CREATE);

    return this.http
      .post(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.create }
      })
      .pipe(map((result: GetCustomFieldResponses) => result?.customFieldResponses));
  }

  createNewLayerProperty(customProperty: DesignLayerProperty) {
    const siteId = this.designsQuery.getSiteId();
    const designId = this.designsQuery.getActiveLayerDesignId();
    const design = this.designsQuery.getRegularDesign(designId);
    const layerId = this.designsQuery.getActiveLayerId();
    const property: CreateFeatureDataRequest = {
      featureId: layerId,
      fieldId: customProperty.fieldId,
      name: customProperty.name,
      value: customProperty.value,
      valueType: customProperty.valueType
    };

    this.analyticsService.designCustomPropertyInteraction(design, property, CustomPropertyInteractionType.CREATE);

    return this.http.post(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, property, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.create }
    });
  }

  updateCustomProperties(properties: DesignLayerProperty[]) {
    const siteId = this.designsQuery.getSiteId();
    const designId = this.designsQuery.getActiveLayerDesignId();
    const updatedProperty: UpdateFeatureDataRequest[] = properties.map(({ id, value }) => ({ id, value }));
    const request: UpdateFeaturesDataRequest = { requestList: updatedProperty };

    if (properties.length === 1 && isDefined(properties[0].value)) {
      const design = this.designsQuery.getRegularDesign(designId);
      this.analyticsService.designCustomPropertyInteraction(design, properties[0], CustomPropertyInteractionType.UPDATE);
    }

    return this.http
      .put(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.update }
      })
      .pipe(map((result: UpdateFeatureDataResponses) => result?.responseList));
  }

  setActiveDesignChat(designId: string) {
    if (isDefined(designId)) {
      const design = this.designsQuery.getRegularDesign(designId);
      this.analyticsService.launchDesignDatuAIChat(design);
    } else {
      this.designsManager.clearAllChatResults();
    }
    this.siteDesignsStore.clearDesignChatHistory();
    this.siteDesignsStore.setActiveDesignChat(designId);
  }

  async setDesignAIResults(designId: string, type: DesignType, selectedOnly: boolean, features: DesignChatResultFeature[]) {
    this.updateDesign(designId, { type, loading: true });
    await this.designsManager.setAIResults(designId, selectedOnly, features);
    const visibleLayers = this.designsManager.getDesignVisibleLayers(designId);
    this.siteDesignsStore.setDesignLayers(designId, type, null, new Set(visibleLayers.map(({ id }) => id)));
    this.updateDesign(designId, { type, loading: false });
  }

  clearChatResults(designId: string) {
    this.designsManager.clearChatResults(designId);
  }

  getAllChatSessions(designId: string, pageToken: DesignChatPageToken) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .post<GetSessionsHistoryResponse>(
        `${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions`,
        pageToken,
        {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.read }
        }
      )
      .pipe(
        tap(({ sessionsList }) => {
          if (designId === this.designsQuery.getActiveDesignChatId()) {
            this.siteDesignsStore.setDesignChatHistory(sessionsList);
          }
        })
      );
  }

  getChatFavoriteSessions(designId: string) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .get<GetFavoriteSessionsResponse>(
        `${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/favorite`,
        {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.read }
        }
      )
      .pipe(
        tap(({ favoriteSessionsList }) => {
          if (designId === this.designsQuery.getActiveDesignChatId()) {
            this.siteDesignsStore.setDesignChatFavorites(favoriteSessionsList);
          }
        })
      );
  }

  getChatSession(designId: string, sessionId: string, pageToken: DesignChatPageToken) {
    const siteId = this.designsQuery.getSiteId();

    return this.http.post<GetSessionQueriesHistoryResponse>(
      `${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/${sessionId}`,
      pageToken,
      {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.read }
      }
    );
  }

  addChatSessionToHistory(session: DesignChatSessionsHistory) {
    this.siteDesignsStore.addChatSessionToHistory(session);
  }

  updateChatSessionLastModified(sessionId: string) {
    const date = new Date().toISOString() as any;
    this.siteDesignsStore.updateChatSession({ sessionId, lastModifiedTime: date });
  }

  editChatSessionTitle(designId: string, sessionId: string, title: string) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .put(
        `${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/${sessionId}`,
        { title },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.update } }
      )
      .pipe(tap(() => this.siteDesignsStore.updateChatSession({ sessionId, chatTitle: title })));
  }

  setChatSessionFavoriteState(designId: string, session: DesignChatSessionsHistory) {
    const siteId = this.designsQuery.getSiteId();
    const isFavorite = !session.favorite;

    return this.http
      .put(
        `${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/${session.sessionId}`,
        { favorite: isFavorite },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.update } }
      )
      .pipe(
        tap(() => {
          this.siteDesignsStore.updateChatSession({ sessionId: session.sessionId, favorite: isFavorite });
          if (isFavorite) {
            this.siteDesignsStore.addChatSessionToFavorites({ ...session, favorite: isFavorite });
          } else {
            this.siteDesignsStore.removeChatSessionFromFavorites(session.sessionId);
          }
        })
      );
  }

  deleteChatSession(designId: string, sessionId: string) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .delete(`${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/${sessionId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.delete }
      })
      .pipe(tap(() => this.siteDesignsStore.deletedChatSession(sessionId)));
  }

  deleteAllChatSessions(designId: string) {
    const siteId = this.designsQuery.getSiteId();

    return this.http
      .delete(`${getServiceUrl('aimanagement')}/sites/${siteId}/designs/${designId}/queries/history/sessions/deleteByDesignId`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designAIChat.delete }
      })
      .pipe(tap(() => this.siteDesignsStore.clearDesignChatHistory()));
  }

  getResourceType(designType: DesignType) {
    switch (designType) {
      case DesignType.REGULAR_DESIGN:
      case DesignType.GEO_REFERENCED_DESIGN:
        return ResourceType.DESIGN;
      case DesignType.ROAD_DESIGN:
        return ResourceType.ROAD_DESIGN;
      default:
        assertNever(designType);
    }
  }
}
