import { Injectable } from '@angular/core';
import { ImageryProvider } from 'cesium';

import { CloudFrontPreSignedPolicy } from '../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { DEFAULT_IMAGERY_TILE_EXTENSION, DEFAULT_IMAGERY_TILE_SIZE, DEFAULT_MAX_DETAIL_LEVEL } from '../utils/cesium-common';
import { GeoUtils, Rectangle } from '../utils/geo';
import { getViewerUrls } from '../utils/viewer-urls';
import { ViewerResourcesService } from './viewer-resources.service';

export interface ImageryMetadata {
  imageryUrl: string;
  boundingRect: Rectangle;
  maximumLevel: number;
  tileFormat: { width: number; height: number; fileExtension: string };
}

export function parseBoundingBox(imageryMetadata: XMLDocument): [number, number, number, number] {
  try {
    const boundingBox = imageryMetadata.getElementsByTagName('BoundingBox')?.[0];

    if (boundingBox) {
      return [
        parseFloat(boundingBox.getAttribute('minx')),
        parseFloat(boundingBox.getAttribute('miny')),
        parseFloat(boundingBox.getAttribute('maxx')),
        parseFloat(boundingBox.getAttribute('maxy'))
      ];
    }
  } catch (error) {
    console.error('Error parsing imagery bounding box', error);
  }

  return null;
}

export function parseBoundingRect(imageryMetadata: XMLDocument) {
  let boundingRect: Rectangle = null;

  try {
    const boundingBox = imageryMetadata.getElementsByTagName('BoundingBox')?.[0];
    if (boundingBox) {
      const [maxx, maxy, minx, miny] = [
        parseFloat(boundingBox.getAttribute('maxx')),
        parseFloat(boundingBox.getAttribute('maxy')),
        parseFloat(boundingBox.getAttribute('minx')),
        parseFloat(boundingBox.getAttribute('miny'))
      ];
      const topRight = GeoUtils.mercatorToWg84(maxx, maxy);
      const bottomLeft = GeoUtils.mercatorToWg84(minx, miny);
      boundingRect = Cesium.Rectangle.fromCartographicArray([topRight, bottomLeft]) as Rectangle;
    }
  } catch (error) {
    console.error('Error parsing imagery bounding rect', error);
    boundingRect = null;
  }

  return boundingRect;
}

export function parseMaxLevel(imageryMetadata: XMLDocument) {
  let maxLevel = DEFAULT_MAX_DETAIL_LEVEL;

  try {
    const tilesets = imageryMetadata.getElementsByTagName('TileSets')?.[0];
    if (tilesets) {
      const lastTileset = tilesets?.children[tilesets.children.length - 1];
      maxLevel = parseInt(lastTileset?.getAttribute('order')) || DEFAULT_MAX_DETAIL_LEVEL;
    }
  } catch (error) {
    console.error('Error parsing imagery max level', error);
    maxLevel = DEFAULT_MAX_DETAIL_LEVEL;
  }

  return maxLevel;
}

export function parseTileFormat(imageryMetadata: XMLDocument) {
  try {
    const tileFormat = imageryMetadata.querySelector('TileFormat');
    return {
      width: Number(tileFormat.getAttribute('width')),
      height: Number(tileFormat.getAttribute('height')),
      fileExtension: tileFormat.getAttribute('extension')
    };
  } catch (error) {
    console.error('Error parsing imagery tile format', error);
    return {
      width: DEFAULT_IMAGERY_TILE_SIZE,
      height: DEFAULT_IMAGERY_TILE_SIZE,
      fileExtension: DEFAULT_IMAGERY_TILE_EXTENSION
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImageryProviderService {
  private imageryMetadataMapping: { [taskId: string]: ImageryMetadata } = {};

  constructor(private resourceService: ViewerResourcesService) {}

  async getImageryProvider(viewerCredentials: CloudFrontPreSignedPolicy, taskId: string) {
    const imageryMetadata = await this.getImageryMetadata(viewerCredentials, taskId);

    const imageryProvider: ImageryProvider = new Cesium.TileMapServiceImageryProvider({
      fileExtension: imageryMetadata.tileFormat.fileExtension,
      tileWidth: imageryMetadata.tileFormat.width,
      tileHeight: imageryMetadata.tileFormat.height,
      maximumLevel: imageryMetadata.maximumLevel,
      url: this.resourceService.createResourceFromUrl(viewerCredentials, imageryMetadata.imageryUrl),
      rectangle: imageryMetadata.boundingRect
    });

    return { imageryProvider, imageryMetadata };
  }

  private async getImageryMetadata(viewerCredentials: CloudFrontPreSignedPolicy, taskId: string) {
    let imageryMetadata = this.imageryMetadataMapping[taskId];
    if (imageryMetadata) {
      return imageryMetadata;
    }

    const imageryUrl = getViewerUrls(viewerCredentials.url, taskId).imagery;

    const xmlResource = this.resourceService.createResourceFromUrl(viewerCredentials, `${imageryUrl}/tilemapresource.xml?`);
    let imageryMetadataXml: XMLDocument;
    try {
      imageryMetadataXml = await xmlResource.fetchXML();
      if (!imageryMetadataXml) {
        throw new Error('Imagery metadata does not exist');
      }

      imageryMetadata = {
        imageryUrl,
        boundingRect: parseBoundingRect(imageryMetadataXml),
        maximumLevel: parseMaxLevel(imageryMetadataXml),
        tileFormat: parseTileFormat(imageryMetadataXml)
      };
      this.imageryMetadataMapping[taskId] = imageryMetadata;
    } catch (e) {
      console.error('Error loading imagery metadata', e);
      imageryMetadata = {
        imageryUrl,
        boundingRect: null,
        maximumLevel: DEFAULT_MAX_DETAIL_LEVEL,
        tileFormat: {
          width: DEFAULT_IMAGERY_TILE_SIZE,
          height: DEFAULT_IMAGERY_TILE_SIZE,
          fileExtension: DEFAULT_IMAGERY_TILE_EXTENSION
        }
      };
    }

    return imageryMetadata;
  }
}
