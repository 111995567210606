/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.8.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExportROIAreaPolygonModel } from './exportROIAreaPolygonModel';

export interface CreateExportROIRequest {
  area?: Array<ExportROIAreaPolygonModel>;
  exportType?: CreateExportROIRequest.ExportTypeEnum;
  fileName?: string;
  inputType?: CreateExportROIRequest.InputTypeEnum;
  resolution?: string;
  taskId?: string;
}
export namespace CreateExportROIRequest {
  export type ExportTypeEnum = 'DSM' | 'DTM' | 'POINT_CLOUD' | 'LANDXML';
  export const ExportTypeEnum = {
    DSM: 'DSM' as ExportTypeEnum,
    DTM: 'DTM' as ExportTypeEnum,
    POINTCLOUD: 'POINT_CLOUD' as ExportTypeEnum,
    LANDXML: 'LANDXML' as ExportTypeEnum
  };
  export type InputTypeEnum = 'DSM' | 'DTM' | 'POINT_CLOUD';
  export const InputTypeEnum = {
    DSM: 'DSM' as InputTypeEnum,
    DTM: 'DTM' as InputTypeEnum,
    POINTCLOUD: 'POINT_CLOUD' as InputTypeEnum
  };
}
