import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';

import { formatFileSize, fromMomentToDate } from './formatting';
import { isDefined } from './general';

export function getFieldErrors(form: FormGroup, ...fieldPath: string[]) {
  const field = form.get(fieldPath);

  return field && !field.disabled && (field.touched || !field.pristine) && !field.valid ? field.errors : null;
}

export function getFieldErrorMessage(form: FormGroup, ...fieldPath: string[]) {
  const errors = getFieldErrors(form, ...fieldPath);
  if (!errors) {
    return '';
  }

  const firstErrorType = Object.keys(errors)[0];
  const errorBody = errors[firstErrorType];
  switch (firstErrorType) {
    case 'required':
      return $localize`:@@general.formFieldErrorMessage.required:This field is required`;
    case 'maxlength': {
      const { requiredLength } = errorBody;
      return $localize`:@@general.formFieldErrorMessage.maxLength:Must have max ${requiredLength} characters`;
    }
    case 'pattern':
      return $localize`:@@general.formFieldErrorMessage.pattern:Invalid format`;
    case 'integer':
      return $localize`:@@general.formFieldErrorMessage.integer:Must be an integer`;
    case 'numberString': {
      const { leadingZeros, integerDigits, fractionDigits } = errorBody as NumberStringValidatorErrors;
      if (leadingZeros) {
        return $localize`:@@general.formFieldErrorMessage.numberStringLeadingZeros:Must not contain leading zeros`;
      } else if (integerDigits) {
        const { required } = integerDigits;
        return $localize`:@@general.formFieldErrorMessage.numberStringIntegerDigits:Must have up to ${required} integer digits`;
      } else if (fractionDigits) {
        const { required } = fractionDigits;
        return $localize`:@@general.formFieldErrorMessage.numberStringFractionDigits:Must have up to ${required} fraction digits`;
      } else {
        return $localize`:@@general.formFieldErrorMessage.numberStringNotNumber:Must be a valid number`;
      }
    }
    case 'min': {
      const { min } = errorBody;
      return min === 0
        ? $localize`:@@general.formFieldErrorMessage.min0:Must be a positive number`
        : $localize`:@@general.formFieldErrorMessage.min:Must be ${min} or greater `;
    }
    case 'max': {
      const { max } = errorBody;
      return $localize`:@@general.formFieldErrorMessage.max:Must be ${max} or less`;
    }
    case 'invalidFileExtension': {
      const validFileExtensions = errorBody.validFileExtensions.join(', ');
      return $localize`:@@general.formFieldErrorMessage.fileInvalidSuffix:File type must be: ${validFileExtensions}`;
    }
    case 'exceedsMaxSize': {
      const maxFileSize = formatFileSize(errorBody.maxFileSize);
      return $localize`:@@general.formFieldErrorMessage.fileMaxSize:File size must be less than ${maxFileSize}`;
    }
    case 'minPolygonPositions': {
      const { min } = errorBody;
      return $localize`:@@general.formFieldErrorMessage.polygonPositionsMinLength:Polygon must have at least ${min} positions`;
    }
    case 'dateFieldBeforeOther': {
      let { otherValue } = errorBody;
      if (moment.isMoment(otherValue)) {
        otherValue = otherValue.toDate();
      }
      const otherDate = otherValue.toLocaleDateString();
      return $localize`:@@general.formFieldErrorMessage.dateFieldAfterOther:Must be before ${otherDate}`;
    }
    case 'dateFieldAfterOther': {
      let { otherValue } = errorBody;
      if (moment.isMoment(otherValue)) {
        otherValue = otherValue.toDate();
      }
      const otherDate = otherValue.toLocaleDateString();
      return $localize`:@@general.formFieldErrorMessage.dateFieldBeforeOther:Must be after ${otherDate}`;
    }
    case 'fieldLargerThanOther': {
      const { otherValue } = errorBody;
      return $localize`:@@general.formFieldErrorMessage.fieldLargerThanOther:Must be larger than ${otherValue}`;
    }
    case 'fieldSmallerThanOther': {
      const { otherValue } = errorBody;
      return $localize`:@@general.formFieldErrorMessage.fieldSmallerThanOther:Must be smaller than ${otherValue}`;
    }
    case 'invalidDate': {
      return errorBody;
    }
    case 'duplicateName': {
      return $localize`:@@general.formFieldErrorMessage.duplicateName:Name already exist`;
    }
    default:
      return '';
  }
}

export function getFormErrors(form: AbstractControl) {
  if (form instanceof FormControl) {
    return form.errors ?? null;
  }

  if (form instanceof FormGroup || form instanceof FormArray) {
    const groupErrors = form.errors;

    let formErrors = groupErrors ? { ...groupErrors } : {};
    Object.keys(form.controls).forEach(key => {
      const errors = getFormErrors(form.get(key));
      if (errors !== null) {
        formErrors = { ...formErrors, ...errors };
      }
    });

    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }
}

export function dateValidator({ minDate, maxDate }: { minDate?: Moment; maxDate?: Moment }): ValidatorFn {
  return (control: AbstractControl<Moment>): ValidationErrors | null => {
    const date = fromMomentToDate(control.value);
    const min = fromMomentToDate(minDate);
    const max = fromMomentToDate(maxDate);

    if (isDefined(minDate) && isDefined(maxDate)) {
      return moment(control.value).isBetween(minDate, maxDate, 'millisecond', '[]')
        ? null
        : { invalidDate: $localize`:@@general.formFieldErrorMessage.invalidDateBetween:Date ${date} should be between ${min} and ${max}` };
    }
    if (isDefined(minDate)) {
      return moment(control.value).isSameOrAfter(minDate)
        ? null
        : { invalidDate: $localize`:@@general.formFieldErrorMessage.invalidDateAfter:Date ${date} should be after ${minDate}` };
    }
    if (isDefined(maxDate)) {
      return moment(control.value).isSameOrBefore(maxDate)
        ? null
        : { invalidDate: $localize`:@@general.formFieldErrorMessage.invalidDateBefore:Date ${date} should be before ${maxDate}` };
    }
  };
}

export const dateFieldBeforeOtherValidator =
  (form: FormGroup, ...otherFieldPath: string[]): ValidatorFn =>
  (control: AbstractControl) => {
    if (!form) {
      return null;
    }

    const otherField = form.get(otherFieldPath);
    if (!control || !isDefined(otherField?.value) || otherField.invalid) {
      return null;
    }

    return moment(control.value).isAfter(otherField.value, 'day') ? { dateFieldBeforeOther: { otherValue: otherField.value } } : null;
  };

export const dateFieldAfterOtherValidator =
  (form: FormGroup, ...otherFieldPath: string[]): ValidatorFn =>
  (control: AbstractControl) => {
    if (!form) {
      return null;
    }

    const otherField = form.get(otherFieldPath);
    if (!control || !isDefined(otherField?.value) || otherField.invalid) {
      return null;
    }

    return moment(control.value).isBefore(otherField.value, 'day') ? { dateFieldAfterOther: { otherValue: otherField.value } } : null;
  };

export const fieldLargerThanOtherValidator =
  (form: FormGroup, ...otherFieldPath: string[]): ValidatorFn =>
  (control: AbstractControl) => {
    if (!form) {
      return null;
    }

    const otherField = form.get(otherFieldPath);
    if (!control || !isDefined(otherField?.value) || otherField.invalid) {
      return null;
    }

    return otherField.value >= control.value ? { fieldLargerThanOther: { otherValue: otherField.value } } : null;
  };

export const fieldSmallerThanOtherValidator =
  (form: FormGroup, ...otherFieldPath: string[]): ValidatorFn =>
  (control: AbstractControl) => {
    if (!form) {
      return null;
    }

    const otherField = form.get(otherFieldPath);
    if (!control || !isDefined(otherField?.value) || otherField.invalid) {
      return null;
    }

    return otherField.value <= control.value ? { fieldSmallerThanOther: { otherValue: otherField.value } } : null;
  };

export const integerValidator: ValidatorFn = (control: AbstractControl) => {
  if (!isDefined(control?.value)) {
    return null;
  }

  return Number.isInteger(control.value) ? null : ({ integer: true } as ValidationErrors);
};

interface NumberStringValidatorErrors extends ValidationErrors {
  numberString: {
    number?: boolean;
    leadingZeros?: boolean;
    integerDigits?: {
      required: number;
      actual: number;
    };
    fractionDigits?: {
      required: number;
      actual: number;
    };
  };
}

const NUMBER_STRING_VALIDATOR_DEFAULT_OPTIONS = {
  integerDigits: null,
  fractionDigits: 3,
  allowLeadingZeros: false
};

/**
 * Creates a validator function to check string fields that should accept only numbers.
 * @param options options object, containing the following optional fields:
 *  - integerDigits - how many digits before the dot are allowed, `null` meaning no limit. Default null.
 *  - fractionDigits - how many digits after the dot are allowed, `null` meaning no limit. Default 3.
 *  - allowLeadingZeros - whether to allow leading zeros. Default false.
 * @returns Validator function, returning errors in the format of {@link NumberStringValidatorErrors}
 */
export function numberStringValidator(options?: {
  integerDigits?: number;
  fractionDigits?: number;
  allowLeadingZeros?: boolean;
}): ValidatorFn {
  options = { ...NUMBER_STRING_VALIDATOR_DEFAULT_OPTIONS, ...options };

  return function (control: AbstractControl): NumberStringValidatorErrors {
    const value = control?.value;
    if (!isDefined(value) || typeof value === 'number') {
      return null;
    }

    // Check if valid number
    if (isNaN(value) || isNaN(parseFloat(value))) {
      return {
        numberString: {
          number: true
        }
      };
    }

    const [integer, fraction] = (value as string).split('.');

    // Test non-existant integer part, or dot with empty integer/fraction part
    if (!isDefined(integer) || fraction === '') {
      return {
        numberString: {
          number: true
        }
      };
    }

    // Test leading zeros
    if (!options?.allowLeadingZeros && integer[0] === '0') {
      return {
        numberString: {
          leadingZeros: true
        }
      };
    }

    // Test integer part
    if (isDefined(options?.integerDigits)) {
      if ((options.integerDigits === 0 && integer !== '0') || integer.length > options.integerDigits) {
        return {
          numberString: {
            integerDigits: {
              required: options.integerDigits,
              actual: integer.length
            }
          }
        };
      }
    }

    // Test fraction part
    if (isDefined(options?.fractionDigits) && isDefined(fraction)) {
      if (options.fractionDigits === 0 || fraction.length > options.fractionDigits) {
        return {
          numberString: {
            fractionDigits: {
              required: options.fractionDigits,
              actual: fraction?.length ?? 0
            }
          }
        };
      }
    }

    return null;
  };
}

export const duplicateNameValidator = (existingNames: string[]): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!isDefined(existingNames)) {
      return null;
    }

    return isDefined(control?.value) && existingNames.some(name => name === control.value) ? { duplicateName: true } : null;
  };
};

export function convertDateToUTCWithoutChangingTime(moment: Moment) {
  return moment.clone().add(moment.utcOffset(), 'minutes');
}

export const fileExtensionFormValidator: (extension?: string, ...restExtensions: string[]) => ValidatorFn =
  (extension: string, ...restExtensions: string[]) =>
  (control: AbstractControl<File | File[] | undefined | null>) => {
    if (!extension) {
      return null;
    }

    const validFileExtensions = [extension, ...restExtensions];
    const value = control?.value;

    if (validFileExtensions.length > 0 && value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          const filenames = value.map(file => file.name);
          const invalidFileExtension = filenames.some(
            filename => !validFileExtensions.some(ext => filename.toLowerCase().endsWith('.' + ext))
          );

          return invalidFileExtension ? { invalidFileExtension: { validFileExtensions } } : null;
        }
      } else if (!validFileExtensions.some(ext => value.name.toLowerCase().endsWith('.' + ext))) {
        return { invalidFileExtension: { validFileExtensions } };
      }
    }

    return null;
  };
