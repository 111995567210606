import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { formatFileSize } from '../utils/formatting';

@Component({
  selector: 'uploading-progress',
  templateUrl: './uploading-progress.component.html',
  styleUrls: ['./uploading-progress.component.scss']
})
export class UploadingProgressComponent implements OnInit {
  formatFileSize = formatFileSize;

  @Output() cancelUpload = new EventEmitter();

  @Input() text: string;
  @Input() hintMessage: string;
  @Input() showProgress = true;

  @Input() totalSize: number;
  @Input() uploadedSize: number;
  @Input() totalFiles: number;
  @Input() uploadedFiles: number;

  constructor() {}

  ngOnInit() {}
}
