import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

import { Position2D } from '../utils/geo';

type Size = { width: number; height: number };
type GrabbingState = (Position2D & { moving: boolean }) | null;

// 'height' - fit image height to container height
// 'width' - fit image width to container width
// 'both' - fit image width and height to container width and height
// 'original' - keep original image size
// 'min-side' - fit image to container width or height, depending on which side is smaller
type ImageFit = 'height' | 'width' | 'both' | 'original' | 'min-side';

type InitialPosition = 'top-left' | 'top-right' | 'center' | 'left' | 'right' | 'bottom-left' | 'bottom-right' | 'bottom-center';

@Component({
  selector: 'zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss']
})
export class ZoomImageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('resizeableContainerRef') resizeableContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild('imageRef') imageRef: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainerRef') imageContainerRef: ElementRef<HTMLDivElement>;

  @Input() src: string;
  @Input() alt: string;
  @Input() maxZoom = 25;
  @Input() zoomStep = 0.2;
  @Input() hideHint = false;
  @Input() withCtrl = false;
  @Input() fit: ImageFit = 'both';
  @Input() initialPosition: InitialPosition = 'top-left';

  @Output() imageContainerContextMenu: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() imageContainerMouseDown: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() imageContainerMouseUpWithoutMove: EventEmitter<MouseEvent> = new EventEmitter();

  @Output() containerMouseUpWithoutMove: EventEmitter<MouseEvent> = new EventEmitter();

  private minZoom = 1;
  private grabbing: GrabbingState = null;
  private originalZoom = 1;
  public imagePosition: Position2D = { x: 0, y: 0 };
  private resizeableContainerResizeObserver: ResizeObserver;

  protected scrolled = false;
  protected loaded = false;

  private get limitedZoom() {
    return this.limitZoom(this.originalZoom);
  }

  ngAfterViewInit() {
    this.subscribeOnResizeContainer();
    this.subscribeImageLoad();
  }

  ngOnDestroy() {
    this.unsubscribeOnResizeContainer();
    this.unsubscribeImageLoad();
  }

  getInitialImagePosition() {
    if (!this.loaded) {
      return { x: 0, y: 0 };
    }

    const { width, height } = this.getInitialImageSize();
    const { offsetWidth, offsetHeight } = this.resizeableContainerRef.nativeElement;

    if (this.initialPosition === 'top-left') {
      return { x: 0, y: 0 };
    } else if (this.initialPosition === 'center') {
      return { x: (offsetWidth - width) / 2, y: (offsetHeight - height) / 2 };
    } else if (this.initialPosition === 'top-right') {
      return { x: offsetWidth - width, y: 0 };
    } else if (this.initialPosition === 'bottom-left') {
      return { x: 0, y: offsetHeight - height };
    } else if (this.initialPosition === 'bottom-right') {
      return { x: offsetWidth - width, y: offsetHeight - height };
    } else if (this.initialPosition === 'bottom-center') {
      return { x: (offsetWidth - width) / 2, y: offsetHeight - height };
    } else if (this.initialPosition === 'left') {
      return { x: 0, y: (offsetHeight - height) / 2 };
    } else if (this.initialPosition === 'right') {
      return { x: offsetWidth - width, y: (offsetHeight - height) / 2 };
    }
  }

  getInitialImageSize(image = this.imageRef.nativeElement): Size {
    const { naturalWidth, naturalHeight } = image;
    const { offsetWidth, offsetHeight } = this.resizeableContainerRef.nativeElement;

    const widthRatio = offsetWidth / naturalWidth;
    const heightRatio = offsetHeight / naturalHeight;

    switch (this.fit) {
      case 'height':
        return { width: naturalWidth * heightRatio, height: offsetHeight };
      case 'width':
        return { width: offsetWidth, height: naturalHeight * widthRatio };
      case 'both':
        return { width: offsetWidth, height: offsetHeight };
      case 'original':
        return { width: naturalWidth, height: naturalHeight };
      case 'min-side':
        return naturalWidth < naturalHeight
          ? { width: offsetWidth, height: naturalHeight * widthRatio }
          : { width: naturalWidth * heightRatio, height: offsetHeight };
      default:
        return { width: offsetWidth, height: offsetHeight };
    }
  }

  public reset() {
    this.originalZoom = 1;
    this.setImagePosition(this.getInitialImagePosition());
    this.setImageSize(this.getInitialImageSize());
  }

  private onLoadStart = () => {
    this.loaded = false;
  };

  private onLoad = () => {
    this.loaded = true;
    this.reset();
  };

  private subscribeImageLoad() {
    this.imageRef.nativeElement.addEventListener('loadstart', this.onLoadStart);
    this.imageRef.nativeElement.addEventListener('load', this.onLoad);
  }

  private unsubscribeImageLoad() {
    this.imageRef.nativeElement.removeEventListener('loadstart', this.onLoadStart);
    this.imageRef.nativeElement.removeEventListener('load', this.onLoad);
  }

  private subscribeOnResizeContainer() {
    this.resizeableContainerResizeObserver = new ResizeObserver(() => {
      this.onContainerResize();
    });

    this.resizeableContainerResizeObserver.observe(this.resizeableContainerRef.nativeElement);
  }

  private unsubscribeOnResizeContainer() {
    this.resizeableContainerResizeObserver.disconnect();
  }

  private onContainerResize() {
    this.reset();
  }

  private isScrollUp(event: WheelEvent): boolean {
    return event.deltaY < 0;
  }

  private isScrollDown(event: WheelEvent): boolean {
    return event.deltaY > 0;
  }

  private adjustZoom(event: WheelEvent): number {
    const zoomFactor = 1 + this.zoomStep;

    if (this.isScrollUp(event) && this.originalZoom < this.maxZoom) {
      return this.originalZoom * zoomFactor;
    } else if (this.isScrollDown(event) && this.originalZoom > this.minZoom) {
      return this.originalZoom / zoomFactor;
    }

    return this.originalZoom;
  }

  private calcZoomByWheelEvent(event: WheelEvent) {
    const defaultResult = {
      originalZoom: this.originalZoom,
      limitedZoom: this.limitedZoom
    };

    if (!this.isScrollUp(event) && !this.isScrollDown(event)) {
      return defaultResult;
    }

    const adjustedZoom = this.adjustZoom(event);

    if (adjustedZoom === this.originalZoom) {
      return defaultResult;
    }

    return {
      originalZoom: adjustedZoom,
      limitedZoom: this.limitZoom(adjustedZoom)
    };
  }

  private calcImageShift({ mouse, prevZoom, newZoom }: { mouse: Position2D; prevZoom: number; newZoom: number }): Position2D {
    // We calculate how many pixels the image position shifts after you change zoom with zoom step
    const zoomDelta = Math.abs(newZoom - prevZoom);

    const x = (mouse.x * zoomDelta) / prevZoom;
    const y = (mouse.y * zoomDelta) / prevZoom;

    return { x, y };
  }

  // Set zoomed image position
  private setImagePosition({ x, y }: Position2D) {
    const { width, height } = this.getInitialImageSize();
    const { offsetWidth, offsetHeight } = this.resizeableContainerRef.nativeElement;

    this.imagePosition.x = x > 0 ? Math.min(0, x) : Math.max(-(width * this.limitedZoom) + offsetWidth, x);
    this.imagePosition.y = y > 0 ? Math.min(0, y) : Math.max(-(height * this.limitedZoom) + offsetHeight, y);

    this.imageContainerRef.nativeElement.style.transform = `translate(${this.imagePosition.x}px, ${this.imagePosition.y}px)`;
  }

  // Set zoomed image size
  private setImageSize({ width, height }: Size) {
    this.imageContainerRef.nativeElement.style.width = `${width}px`;
    this.imageContainerRef.nativeElement.style.height = `${height}px`;
  }

  private setGrabbing(newGrabbing: GrabbingState) {
    if (newGrabbing) {
      this.imageContainerRef.nativeElement.style.cursor = newGrabbing.moving ? 'grab' : '';
      this.grabbing = newGrabbing;
    } else {
      this.imageContainerRef.nativeElement.style.cursor = '';
      this.grabbing = null;
    }
  }

  private limitZoom(zoom: number) {
    return Math.max(this.minZoom, Math.min(this.maxZoom, zoom));
  }

  public unzoomPoint(point: Position2D): Position2D {
    const zoom = this.limitZoom(this.originalZoom);
    return { x: point.x / zoom, y: point.y / zoom };
  }

  public zoomPoint(point: Position2D): Position2D {
    const zoom = this.limitZoom(this.originalZoom);
    return { x: point.x * zoom, y: point.y * zoom };
  }

  public toScaledPoint(naturalPoint: Position2D, image = this.imageRef.nativeElement): Position2D {
    const zoom = this.limitZoom(this.originalZoom);
    const { naturalWidth, naturalHeight } = image;
    const { width, height } = this.getInitialImageSize(image);
    const widthRatio = width / naturalWidth;
    const heightRatio = height / naturalHeight;

    return {
      x: zoom * naturalPoint.x * widthRatio,
      y: zoom * naturalPoint.y * heightRatio
    };
  }

  public toNaturalPoint(scaledPoint: Position2D, image = this.imageRef.nativeElement): Position2D {
    const zoom = this.limitZoom(this.originalZoom);
    const { naturalWidth, naturalHeight } = image;
    const { width, height } = this.getInitialImageSize(image);
    const widthRatio = width / naturalWidth;
    const heightRatio = height / naturalHeight;

    return {
      x: scaledPoint.x / zoom / widthRatio,
      y: scaledPoint.y / zoom / heightRatio
    };
  }

  private canZoomOrMove(event: WheelEvent | MouseEvent) {
    return this.loaded && this.withCtrl ? event.ctrlKey || event.metaKey : true;
  }

  private getMouseOffset(event: MouseEvent): Position2D {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    return { x: event.clientX - rect.left, y: event.clientY - rect.top };
  }

  private isLeftMouseButton(event: MouseEvent) {
    return event.button === 0;
  }

  protected onImageWheel(event: WheelEvent) {
    if (this.canZoomOrMove(event)) {
      event.preventDefault();
      this.scrolled = true;

      const { originalZoom, limitedZoom: newLimitedZoom } = this.calcZoomByWheelEvent(event);
      const prevLimitedZoom = this.limitedZoom;
      const zoomDelta = newLimitedZoom - prevLimitedZoom;

      if (zoomDelta === 0) {
        return;
      }

      this.originalZoom = originalZoom;

      const { width: initialImageWidth, height: initialImageHeight } = this.getInitialImageSize();

      const newImageWidth = initialImageWidth * newLimitedZoom;
      const newImageHeight = initialImageHeight * newLimitedZoom;

      const mouse = this.getMouseOffset(event);
      const shift = this.calcImageShift({ mouse, prevZoom: prevLimitedZoom, newZoom: newLimitedZoom });

      if (zoomDelta > 0) {
        this.setImagePosition({ x: this.imagePosition.x - shift.x, y: this.imagePosition.y - shift.y });
      } else {
        this.setImagePosition({ x: this.imagePosition.x + shift.x, y: this.imagePosition.y + shift.y });
      }

      this.setImageSize({ width: newImageWidth, height: newImageHeight });
    }
  }

  protected onImageMouseDown(event: MouseEvent) {
    if (this.isLeftMouseButton(event) && this.canZoomOrMove(event)) {
      event.preventDefault();
      this.setGrabbing({ ...this.getMouseOffset(event), moving: false });
    }

    this.imageContainerMouseDown.emit(event);
  }

  // This function returns the coordinates of the picture taking into account the shift and zoom of the picture.
  // This function takes the coordinates of a point in the container.
  public fromContainerPoint(containerPoint: Position2D): Position2D {
    return { x: containerPoint.x - this.imagePosition.x, y: containerPoint.y - this.imagePosition.y };
  }

  private removeGrabbing() {
    this.setGrabbing(null);
  }

  protected onImageMouseUp(event: MouseEvent) {
    if (this.isLeftMouseButton(event) && this.grabbing) {
      event.preventDefault();

      if (!this.grabbing.moving) {
        this.imageContainerMouseUpWithoutMove.emit(event);
      }

      this.removeGrabbing();
    }
  }

  protected onImageMouseLeave(event: MouseEvent) {
    event.preventDefault();
    this.removeGrabbing();
  }

  protected onImageMouseMove(event: MouseEvent) {
    if (this.canZoomOrMove(event) && this.grabbing) {
      event.preventDefault();
      // If the user moves the mouse, we consider it as a move
      this.setGrabbing({ ...this.grabbing, moving: true });
      const mouse: Position2D = this.getMouseOffset(event);
      const shift: Position2D = { x: mouse.x - this.grabbing.x, y: mouse.y - this.grabbing.y };
      this.setImagePosition({ x: this.imagePosition.x + shift.x, y: this.imagePosition.y + shift.y });
    }
  }
}
